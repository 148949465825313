import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { useRecoilState } from 'recoil';
import { Autocomplete, Button, Modal, SvgIcon } from '@ui-elements';
import { UserLocationState } from '~/common/store/user-location-state';
import { capitalizeFirstLetter } from '~/common/utils/formatters';
import { setConfiguratorPostcode } from '~/common/services/dxp-postcode-service';
import { Option } from '~/common/models';
import styles from './index.module.scss';

interface Props {
  closeModal: () => void;
}

type PostCodeOption = Option & {
  name: string;
};

export const PostcodeModal = React.memo<Props>(({ closeModal }) => {
  const autocompleteRef = React.useRef<AsyncSelect<Option> | null>(null);

  const [currentLocation, setLocationState] = useRecoilState(UserLocationState);
  const [defaultOptions, setDefaultOptions] = useState<PostCodeOption[]>([]);
  const [suburb, setSuburb] = useState<PostCodeOption | undefined>(undefined);

  useEffect(() => {
    const option = {
      name: currentLocation.name,
      label: currentLocation.fullName,
      value: currentLocation.postcode,
    } as PostCodeOption;

    setDefaultOptions([option]);
    setSuburb(option);

    window['pushDigitalData'](
      {
        event: '_formNavigate',
        form: {
          name: 'change postcode',
          stage: 'change postcode',
        },
      },
      true
    );
  }, []);

  const asyncLoadOptions = (
    value: string,
    callback: (options: Option[]) => void
  ) => {
    if (value.length <= 2) {
      callback([]);
      setDefaultOptions([]);
      return;
    }

    fetch(`/api/location?search=${value}`)
      .then((res) => res.json())
      .then((res) => {
        const options = res.map((location) => ({
          label: `${location.name}, ${location.stateAbbreviationName} ${location.postcode}`,
          value: location.postcode,
          name: location.name,
        })) as PostCodeOption[];

        callback(options);
        setDefaultOptions(options);
      });
  };

  const handleOptionChange = (value: Option | null) => {
    if (!!value) {
      const postcodeOption = value as PostCodeOption;
      setSuburb(postcodeOption);
    }
  };

  const showPrices = () => {
    if (!!suburb) {
      const location = {
        name: capitalizeFirstLetter(suburb.name),
        fullName: suburb.label,
        postcode: suburb.value,
      };

      setConfiguratorPostcode(location.postcode).then(() => {
        const param = { detail: { suburb: location } };
        window.dispatchEvent(new CustomEvent('dealerChanged', param));
      });

      localStorage.setItem('suburb', JSON.stringify(location));
      localStorage.setItem('postalCode', suburb.value);
      setLocationState(location);

      const _data = {
        event: '_formNavigate',
        form: {
          name: 'change postcode',
          stage: 'submitted',
          details: {
            vehicleSelected: [
              {
                make: 'subaru',
              },
            ],
          },
        },
        user: {
          postcode: suburb.value,
        },
      };
      if (window['ModelName'])
        _data.form.details.vehicleSelected[0]['model'] = window['ModelName'];

      window['pushDigitalData'](_data, true);

      closeModal();
    }
  };

  return (
    <Modal closeModal={closeModal} lockScroll={false}>
      <div className={styles.postcodeModal}>
        <p className={styles.text1}>What&apos;s your postcode?</p>
        <p className={styles.text2}>
          Please enter your postcode so we can find accurate prices for your
          area.
        </p>
        <Autocomplete
          rounded
          bordered
          value={suburb}
          ref={autocompleteRef}
          defaultOptions={defaultOptions}
          loadOptions={asyncLoadOptions}
          onChange={handleOptionChange}
          dropdownIndicator={() => (
            <SvgIcon
              type="search"
              size={1.8}
              color="#1637a0"
              className={styles.searchIcon}
              onClick={() => {
                autocompleteRef.current?.focus();
              }}
            />
          )}
          className={styles.postcodeTextField}
          placeholder="Enter Postcode"
          name="postcode"
        />
        <Button
          buttonSize="large"
          onClick={showPrices}
          className={styles.showPriceButton}
        >
          Show prices
        </Button>
      </div>
    </Modal>
  );
});
