import React from 'react';

export const useIsTablet = () => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const isTablet = () => {
    if (typeof document === 'undefined') return false;
    return window.innerWidth < 1024;
  };

  return { containerRef, isTablet };
};
