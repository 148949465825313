import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import {
  DragOverlay,
  DropAnimation,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import styles from './index.module.scss';

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
};

interface Props {
  dragging?: boolean;
}
export function SortableOverlay({ children }: PropsWithChildren<Props>) {
  return (
    <DragOverlay
      className={cn(styles.sortableOverlay, styles.dragging)}
      dropAnimation={dropAnimationConfig}
    >
      {children}
    </DragOverlay>
  );
}
