import { atom, selector, selectorFamily } from 'recoil';
import { PriceInfo } from '../models';
import { RepaymentInfo } from '~/common/models';
import { UserLocationDealer } from '../services/dealer-service';

export interface UserLocation {
  name: string;
  postcode: string;
  fullName: string;
}

export const getLocationFromStorage = (): UserLocation => {
  if (typeof window !== 'undefined') {
    const suburb = localStorage.getItem('suburb');
    if (!!suburb) {
      return JSON.parse(suburb) as UserLocation;
    }
  }
  return {
    name: 'Sydney',
    fullName: 'Sydney NSW, 2000',
    postcode: '2000',
  };
};

export const getLocationDealerFromStorage = (): UserLocationDealer => {
  if (typeof window !== 'undefined') {
    const dealer = localStorage.getItem('v4_customer_data_');
    if (!!dealer) {
      const _dealer = JSON.parse(dealer);
      return {
        name: _dealer?.selectedDealer?.label
          ?.toLowerCase()
          ?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
        crmDealerId: _dealer?.dealerId,
        postcode: _dealer?.postcode,
        uniqueName: '',
      };
    }
  }

  return {
    name: 'Subaru Sydney City',
    crmDealerId: '122390',
    postcode: '2000',
    uniqueName: 'subaru-sydney-city',
  };
};

export const getPostcodeFromStorage = (): string => {
  if (typeof window !== 'undefined') {
    const dxp_data = localStorage.getItem('v4_customer_data_');
    if (!!dxp_data) {
      return JSON.parse(dxp_data).postcode as string;
    }
  }

  return '2000';
};

export const UserLocationState = atom<UserLocation>({
  key: 'UserLocation',
  default: getLocationFromStorage(),
});

export const UserLocationDealerState = atom<UserLocationDealer>({
  key: 'UserLocationDealer',
  default: getLocationDealerFromStorage(),
});

export const getUserPostcode = selector({
  key: 'UserPostcode',
  get: () => getPostcodeFromStorage(),
});

export const getLocationDealer = selector({
  key: 'GetUserLocationDealer',
  get: () => getLocationDealerFromStorage(),
});

export const getPriceByPostcodeQuery = selectorFamily<
  PriceInfo,
  string | undefined
>({
  key: 'VariantPriceByPostcodeQuery',
  get:
    (id) =>
    async ({ get }) => {
      if (!!id) {
        const postcode = get(UserLocationState).postcode;
        const res = await fetch(`/api/pricing/${postcode}/${id}`);
        const priceInfo = (await res.json()) as PriceInfo;
        return priceInfo;
      }
      return {} as PriceInfo;
    },
});

export const getUserLocation = selectorFamily<UserLocation | undefined, string>(
  {
    key: 'GetUserLocation',
    get: (postcode) => async () => {
      const response = await fetch(`/api/location?search=${postcode}`).then(
        (res) => res.json()
      );

      const locations = response.map((location) => ({
        fullName: `${location.name}, ${location.stateAbbreviationName} ${location.postcode}`,
        postcode: location.postcode,
        name: location.name,
        state: location.stateAbbreviationName,
      })) as UserLocation[];

      return locations.find((x) => x.postcode === postcode);
    },
  }
);

export const getFinanceFormInput = (
  financeFormInputEvent: any,
  rdp: number
) => {
  let financeFormInput: any = null;
  if (financeFormInputEvent) {
    financeFormInput = financeFormInputEvent.detail.event;
  } else if (
    typeof window !== 'undefined' &&
    localStorage.getItem('au_sub-dis_finance_form_input')
  ) {
    financeFormInput = JSON.parse(
      localStorage.getItem('au_sub-dis_finance_form_input') ?? ''
    );
  } else {
    // setting default values
    if (financeFormInput === null && rdp >= 0) {
      financeFormInput = {
        paymentType: 'finance',
        financeProductId: 'FIN_PROD_AUS_SUB_GFV',
        mileage: 15000,
        financePackageId: 'Weekly',
        deposit: 0,
        term: 48,
        ageRange: '23',
        homeOwner: 'true',
        creditRating: 'GOOD',
      };
    }
  }
  return financeFormInput;
};

export const financeFormInputState = atom<any>({
  key: 'FinanceFormInput',
  default: getFinanceFormInput(undefined, 0),
});

export const getRepaymentInfoByPostcodeQuery = selectorFamily<
  RepaymentInfo,
  string | undefined
>({
  key: 'RepaymentInfoByPostcodeQuery',
  get:
    (pimIdentifier) =>
    async ({ get }) => {
      if (pimIdentifier === undefined) {
        return {} as RepaymentInfo;
      }
      const postcode = get(UserLocationState).postcode;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          codes: [pimIdentifier],
          financeFormInput: get(financeFormInputState),
          postCode: postcode,
        }),
      };

      return await fetch(`/proxy/api/financecalculator`, requestOptions)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error('Server error occurred.');
        })
        .then((res) => {
          const thisFinanceProductId = get(
            financeFormInputState
          ).financeProductId;
          const errorDescription =
            thisFinanceProductId === 'FIN_PROD_AUS_SUB_GFV' &&
            (res.finance.simulationInfo[0].result.type === 'not_applicable' ||
              res.finance.simulationInfo[0].result.simulation.financeProduct !==
                'FIN_PROD_AUS_SUB_GFV')
              ? 'Sorry, GFV isn’t yet available for this vehicle. Please check with your local participating Retailer or check back soon.'
              : res.finance.simulationInfo[0].result.type === 'na'
              ? 'Based on your deposit, the loan amount on this vehicle would be too low. Please lower your deposit to see a repayment.'
              : res.finance.simulationInfo[0].result.type === 'error'
              ? 'Something went wrong with your request. Please try again later.'
              : '';

          if (errorDescription !== '') {
            const repaymentInfo: RepaymentInfo = {
              pimIdentifier,
              financeProductId: thisFinanceProductId,
              comparisonRate: 0,
              interestRate: 0,
              paymentValue: 0,
              monthlyRepayment: '',
              paymentFrequency: '',
              paymentFrequencyString: '',
              deposit: 0,
              term: '',
              residualValue: 0,
              postCode: '',
              mileage: 0,
              isGfvNotAvailableError:
                thisFinanceProductId === 'FIN_PROD_AUS_SUB_GFV' &&
                (res.finance.simulationInfo[0].result.type ===
                  'not_applicable' ||
                  res.finance.simulationInfo[0].result.simulation
                    .financeProduct !== 'FIN_PROD_AUS_SUB_GFV'),
              isDepositTooHighError:
                res.finance.simulationInfo[0].result.type === 'na',
              isGeneralError:
                res.finance.simulationInfo[0].result.type === 'error',
              errorDescription,
            };
            const eventObj = {
              detail: null,
              pimIdentifier,
            };
            window.dispatchEvent(
              new CustomEvent('repaymentInfoUpdated', eventObj)
            );
            return repaymentInfo;
          }

          const repaymentInfo: RepaymentInfo = {
            pimIdentifier,
            financeProductId: thisFinanceProductId,
            comparisonRate:
              res.finance.simulationInfo[0].result.simulation.comparisonRate,
            interestRate:
              res.finance.simulationInfo[0].result.simulation.interestRate,
            paymentValue:
              res.finance.simulationInfo[0].result.simulation.paymentValue[0]
                .amount / 100,
            monthlyRepayment: getMonthlyAmountFromDisclaimers(
              res.finance.simulationInfo[0].disclaimers
            ),
            paymentFrequency:
              res.finance.simulationInfo[0].result.simulation.financePackageId,
            paymentFrequencyString: getPaymentFrequencyString(
              res.finance.simulationInfo[0].result.simulation.financePackageId
            ),
            deposit:
              res.finance.simulationInfo[0].result.simulation.deposits[0]
                .value / 100,
            term: res.finance.simulationInfo[0].result.simulation.loanTermPeriod
              .value,
            residualValue:
              res.finance.simulationInfo[0].result.simulation.residualValue ===
              null
                ? 0
                : res.finance.simulationInfo[0].result.simulation.residualValue
                    ?.amount / 100,
            postCode: res.finance.simulationInfo[0].postCode,
            mileage: res.finance.simulationInfo[0].result.simulation.mileage,
            isGfvNotAvailableError:
              thisFinanceProductId === 'FIN_PROD_AUS_SUB_GFV' &&
              (res.finance.simulationInfo[0].result.type === 'not_applicable' ||
                res.finance.simulationInfo[0].result.simulation
                  .financeProduct !== 'FIN_PROD_AUS_SUB_GFV'),
            isDepositTooHighError:
              res.finance.simulationInfo[0].result.type === 'na',
            isGeneralError:
              res.finance.simulationInfo[0].result.type === 'error',
            errorDescription:
              thisFinanceProductId === 'FIN_PROD_AUS_SUB_GFV' &&
              (res.finance.simulationInfo[0].result.type === 'not_applicable' ||
                res.finance.simulationInfo[0].result.simulation
                  .financeProduct !== 'FIN_PROD_AUS_SUB_GFV')
                ? 'Sorry, GFV isn’t yet available for this vehicle. Please check with your local participating Retailer or check back soon.'
                : res.finance.simulationInfo[0].result.type === 'na'
                ? 'Based on your deposit, the loan amount on this vehicle would be too low. Please lower your deposit to see a repayment.'
                : res.finance.simulationInfo[0].result.type === 'error'
                ? 'Something went wrong with your request. Please try again later.'
                : '',
          };
          const evt: CustomEvent = new CustomEvent('tabChanged');
          window.dispatchEvent(evt);

          const eventObj = {
            detail: res.finance.simulationInfo[0].result.simulation,
            pimIdentifier,
          };
          eventObj.detail.isFinanceFormInputPersonalised =
            getFinanceFormInputPersonalised();
          window.dispatchEvent(
            new CustomEvent('repaymentInfoUpdated', eventObj)
          );
          return repaymentInfo;
        })
        .catch(() => {
          const thisFinanceProductId = get(
            financeFormInputState
          ).financeProductId;
          const repaymentInfo: RepaymentInfo = {
            pimIdentifier,
            financeProductId: thisFinanceProductId,
            comparisonRate: 0,
            interestRate: 0,
            paymentValue: 0,
            monthlyRepayment: '',
            paymentFrequency: '',
            paymentFrequencyString: '',
            deposit: 0,
            term: '',
            residualValue: 0,
            postCode: '',
            mileage: 0,
            isGfvNotAvailableError: false,
            isDepositTooHighError: false,
            isGeneralError: true,
            errorDescription:
              'Something went wrong with your request unfortunately. Please try again later.',
          };
          return repaymentInfo;
        });
    },
});

export const getFinanceFormInputPersonalised = () => {
  return localStorage.getItem('au_sub-dis_finance_form_input') !== null;
};

export const getMonthlyAmountFromDisclaimers = (disclaimers: any): string => {
  const repaymentDisclaimer = disclaimers.find(
    (x) => x.purpose === 'Repayment'
  );
  if (repaymentDisclaimer) {
    const inputString = repaymentDisclaimer.value;
    // Regular expression pattern to match dollar values
    const dollarPattern = /repayment is \$\d{1,3}(,\d{3})*(\.\d{2})?/;

    // Find all dollar values in the string
    const dollarValues = inputString.match(dollarPattern);
    const dollarStr = dollarValues[0].substring(13); // discarding the $ sign as well
    return dollarStr;
  }
  return '';
};

export const getPaymentFrequencyString = (paymentFrequency: string): string => {
  if (paymentFrequency === 'Weekly') {
    return 'week';
  } else if (paymentFrequency === 'Fortnightly') {
    return 'fortnight';
  } else if (paymentFrequency === 'Monthly') {
    return 'month';
  }
  return 'week';
};
