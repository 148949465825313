import React from 'react';
import cn from 'classnames';
import { InteriorOption } from '~/common/models';
import styles from './index.module.scss';

interface InteriorSwatchProps {
  key?: string | number;
  isActive?: boolean;
  interior: InteriorOption;
  className?: string;
  onClick: () => void;
}

export const InteriorSwatch = React.memo<InteriorSwatchProps>(
  ({ isActive, className, onClick, interior }) => {
    return (
      <div
        onClick={onClick}
        className={cn(styles.interiorOption, className, {
          [styles.selected]: isActive,
        })}
      >
        <img src={interior.imageUrl} alt={interior.trimCode} />
      </div>
    );
  }
);
