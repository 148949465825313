import { atom, selector, selectorFamily } from 'recoil';
import {
  CompareVariant,
  CompareSpecGroup,
  CompareSpecItem,
  CompareModel,
  VehicleOption,
  CompareCar,
  CompareHeading,
} from './models';
import {
  getAllModels,
  getVariant,
  setCompareCarsLocalStore,
  getCompareCarsLocalStore,
} from './services';
import { addSpare, noVehicleSelected } from './utils';

const localStorageEffect =
  () =>
  ({ setSelf, onSet, trigger }) => {
    const loadPersisted = async () => {
      setSelf(
        addSpare(
          getCompareCarsLocalStore().map(
            (c) =>
              ({
                id: c.id,
                modelPimId: c.modelPimIdentifier,
                variantPimId: c.variantPimIdentifier,
              } as CompareCar)
          )
        )
      );
    };

    if (trigger === 'get') {
      loadPersisted();
    }

    onSet((newValue: CompareCar[]) => {
      setCompareCarsLocalStore(
        newValue
          .filter((v) => !noVehicleSelected(v))
          .map((v) => ({
            id: v.id,
            modelPimIdentifier: v.modelPimId,
            variantPimIdentifier: v.variantPimId,
          }))
      );

      setSelf(addSpare(newValue));
    });
  };

export const CompareCarsLocalStorage = atom<CompareCar[]>({
  key: 'CompareCarsLocalStorage',
  default: [],
  effects: [localStorageEffect()],
});

export const GetAllModelsAndVariantsQuery = selector<CompareModel[]>({
  key: 'GetAllModelsAndVariantsQuery',
  get: async () => await getAllModels(),
});

export const GetModelOptionsQuery = selector<VehicleOption[]>({
  key: 'GetModelOptionsQuery',
  get: ({ get }) => {
    const allVehicles = get(GetAllModelsAndVariantsQuery);
    return allVehicles.map((m: CompareModel) => ({
      id: m.pimID,
      label: m.name,
      value: m.pimID,
      isDisabled: false,
    }));
  },
});

export const GetModelVariantsQuery = selectorFamily<VehicleOption[], string>({
  key: 'GetModelVariantsQuery',
  get:
    (modelId: string) =>
    ({ get }) => {
      const allModels = get(GetAllModelsAndVariantsQuery);
      const selectedVehicles = get(CompareCarsLocalStorage);
      const model = allModels.find((x) => x.pimID === modelId) || {
        variants: [],
      };
      return model.variants.map((v: CompareVariant) => ({
        id: v.pimID,
        label: v.name,
        value: v.pimID,
        isDisabled: selectedVehicles.some((s) => s.variantPimId === v.pimID),
      }));
    },
});

type CompareVehicleParams = {
  modelId: string;
  variantId: string;
};
export const GetCompareModelVariantQuery = selectorFamily<
  CompareCar,
  CompareVehicleParams
>({
  key: 'CompareModelVariantQuery',
  get: (param: CompareVehicleParams) => async () => {
    return await getVariant(param.modelId, param.variantId);
  },
});

export const CompareVariantInfoQuery = selectorFamily<
  CompareCar | undefined,
  string
>({
  key: 'CompareVariantInfoQuery',
  get:
    (id: string) =>
    ({ get }) => {
      return get(CompareCarsLocalStorage).find((c) => c.id === id);
    },
});

export const CompareSpecGroupState = atom<CompareSpecGroup[]>({
  key: 'CompareSpecGroupState',
  default: [],
});

export const CompareSpecItemsState = atom<CompareSpecItem[]>({
  key: 'CompareSpecItemsState',
  default: [],
});

export const CompareHeadingState = atom<CompareHeading>({
  key: 'CompareHeadingState',
  default: {
    headerTitle: 'Compare Models and Variants',
    headerDescription: '',
    headerTooltip: '',
    specsTooltip: '',
    featuresTooltip: '',
  },
});
