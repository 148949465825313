import React from 'react';
import { HashRouteContext, Params, RouteOption } from './context';

export * from './context';
export * from './link';
export * from './route';
export * from './redirectContainer';

interface HashRouterProps {
  defaultRoute?: string;
  children: JSX.Element;
  disableHash?: boolean;
}
export const HashRouter: React.FC<HashRouterProps> = ({
  defaultRoute,
  children,
  disableHash,
}) => {
  const [route, setRoute] = React.useState(defaultRoute);
  const [params, setParams] = React.useState<Params | null>(null);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const path = getRoutePath(window.location.hash);
      if (path) setRoute(path);
    }
  }, []);

  const routeContext = {
    route,
    params,
    navigate: (path: string, options?: RouteOption) => {
      const _path = getRoutePath(path);
      const _params = buildRouteParams(options?.params);
      const newPath = _params ? `${path}?${_params}` : path;

      setRoute(disableHash ? path : (_path as string));
      setParams(options?.params as Params);

      if (disableHash) window.history.pushState({}, '', newPath);
      else window.location.hash = newPath;

      if (options?.resetScroll !== false) window.scroll(0, 0);
    },
  };

  const getRoutePath = (path: string) => {
    const regex = new RegExp(/\/[a-zA-Z-]*/g);
    const match = path.match(regex);
    return match && match.length > 0 ? match[0] : null;
  };

  const buildRouteParams = (p?: Params) => {
    return p
      ? Object.keys(p).reduce(
          (cur, key, idx, _) =>
            `${cur}${key}=${p[key]}${idx === _.length - 1 ? '' : '&'}`,
          ''
        )
      : null;
  };

  return (
    <HashRouteContext.Provider value={routeContext}>
      {children}
    </HashRouteContext.Provider>
  );
};
