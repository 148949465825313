import React from 'react';
import uuid from 'react-uuid';
import cn from 'classnames';
import { Select } from '@ui-elements';
import { OptionTypeBase } from 'react-select';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useSetRecoilState, useRecoilValueLoadable } from 'recoil';
import { replaceItemAtIndex } from '~/common/utils';
import { CompareCarsLocalStorage, GetModelOptionsQuery } from '../../store';
import { CompareCar } from '../../models';
import { DropdownStyles } from './dropdown-style';
import styles from './index.module.scss';

interface VehicleModelDropdownProps {
  id: UniqueIdentifier;
  modelId: string;
  fullWidth?: boolean;
}

export const VehicleModelDropdown = React.memo<VehicleModelDropdownProps>(
  ({ id, modelId, fullWidth }) => {
    const setCompareCars = useSetRecoilState(CompareCarsLocalStorage);
    const vehicleOptions = useRecoilValueLoadable(GetModelOptionsQuery);

    const getValue = () => {
      if (vehicleOptions.state === 'hasValue') {
        return vehicleOptions.getValue().find((v) => v.id === modelId);
      }
    };

    const handleOnChange = (newValue: OptionTypeBase | null) => {
      window['pushDigitalData'](
        {
          event: '_formNavigate',
          form: {
            name: 'compare subaru range',
            stage: 'select cars',
            field: {
              name: 'model',
              value: newValue?.label,
            },
          },
        },
        true
      );
      setCompareCars((items) => {
        const index = items.findIndex((v) => v.id === id);
        return replaceItemAtIndex<CompareCar>(items, index, {
          id: uuid(),
          modelName: newValue?.label,
          modelPimId: newValue?.value,
          variantPimId: '',
        });
      });
    };

    return (
      <div
        className={cn(styles.vehicleDropdown, !fullWidth && styles.halfWidth)}
      >
        <label>Model</label>
        {typeof window !== 'undefined' && (
          <Select
            isMulti={false}
            value={getValue()}
            styles={DropdownStyles}
            options={vehicleOptions.valueMaybe()}
            isLoading={vehicleOptions.state === 'loading'}
            onChange={handleOnChange}
            menuPlacement="auto"
            menuPosition="fixed"
            openMenuOnClick
            openMenuOnFocus
            components={{
              IndicatorSeparator: null,
            }}
            closeMenuOnScroll={(e) => {
              if (e.target === document) {
                return true;
              }
              return false;
            }}
          />
        )}
      </div>
    );
  }
);
