import React, { useEffect } from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';
import { Loading, SvgIcon } from '@ui-elements';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { SortableDragHandle } from '~/common/components';
import {
  VehicleModelDropdown,
  VehicleVariantDropdown,
} from '../vehicle-dropdown';
import { VariantFinance } from '../variant-finance';
import { VehicleOptions } from '../vehicle-options';
import { VehicleSpecsFeat } from '../vehicle-specs-feat';
import { CompareCTAButton } from '../cta-button';
import {
  CompareCarsLocalStorage,
  CompareVariantInfoQuery,
  GetCompareModelVariantQuery,
} from '../../store';
import { replaceItemAtIndex } from '~/common/utils';
import { CompareCar } from '../../models';
import styles from './index.module.scss';

interface CompareCardProps {
  id: UniqueIdentifier;
  modelId: string;
  variantId: string;
  onRemove: (id: UniqueIdentifier) => void;
}

export const CompareCard = React.memo<CompareCardProps>(
  ({ id, modelId, variantId, onRemove }) => {
    return (
      <div className={styles.compareCard}>
        <div className={styles.topBar}>
          {modelId !== '' && variantId !== '' && (
            <>
              <SortableDragHandle />
              <button
                onClick={() => onRemove(id)}
                className={styles.removeButton}
              >
                <span>Remove</span>
                <SvgIcon type="close" color="#1637A0" size={1.3} />
              </button>
            </>
          )}
        </div>
        <div className={styles.contentWrapper}>
          <VehicleModelDropdown id={id} modelId={modelId} />
          <VehicleVariantDropdown
            id={id}
            modelId={modelId}
            variantId={variantId}
          />
          {modelId !== '' && variantId !== '' && (
            <CompareVariantDetails
              id={id}
              modelId={modelId}
              variantId={variantId}
            />
          )}
        </div>
      </div>
    );
  }
);

interface CompareVariantDetailsProp {
  id: UniqueIdentifier;
  modelId: string;
  variantId: string;
}
const CompareVariantDetails = React.memo<CompareVariantDetailsProp>(
  ({ id, modelId, variantId }) => {
    const setCompareCars = useSetRecoilState(CompareCarsLocalStorage);
    const variantLoadable = useRecoilValueLoadable(
      GetCompareModelVariantQuery({ modelId, variantId })
    );

    useEffect(() => {
      if (variantLoadable.state === 'hasValue') {
        const vehicle = variantLoadable.getValue();
        setCompareCars((items) => {
          const index = items.findIndex((i) => i.variantPimId === variantId);
          return replaceItemAtIndex<CompareCar>(items, index, {
            ...items[index],
            previewImageUrl: vehicle.variant?.imageUrl,
            modelName: vehicle.modelName,
            variant: vehicle.variant,
          });
        });
      }
    }, [variantLoadable]);

    const handleSetPreviewImage = (previewImageUrl?: string) => {
      setCompareCars((items) => {
        const index = items.findIndex((i) => i.variantPimId === variantId);
        return replaceItemAtIndex<CompareCar>(items, index, {
          ...items[index],
          previewImageUrl,
        });
      });
    };

    switch (variantLoadable.state) {
      case 'hasValue':
        const data = variantLoadable.getValue();
        // data layer
        let ctaData: [string, string | null] | null = null;
        if (data && typeof data === 'object') {
          ctaData = [data.modelName as string, data.variant?.name || null];
        }

        if (!!data.variant) {
          return (
            <>
              <div className={styles.previewImageContainer}>
                <ComparePreviewImage id={id} />
              </div>

              <VariantFinance
                modelName={data.modelName || ''}
                variantId={data.variant.id}
                legacyId={data.variant.legacyId}
              />
              <VehicleOptions
                colours={data.variant.colours}
                interiors={data.variant.interiors}
                onSelectOption={handleSetPreviewImage}
              />
              <CompareCTAButton
                cta={data.variant.ctaLearnMore}
                data={ctaData}
              />
              <VehicleSpecsFeat specs={data.variant.specs} />
              <CompareCTAButton
                cta={data.variant.ctaBookaTestDrive}
                data={ctaData}
              />
              <CompareCTAButton
                cta={data.variant.ctaBuildYourOwn}
                data={ctaData}
              />
              <CompareCTAButton
                cta={data.variant.ctaBrochureDownload}
                data={ctaData}
              />
            </>
          );
        }
        break;

      case 'loading':
        return <Loading />;
    }

    return <div>ERROR</div>;
  }
);

interface ComparePreviewImageProps {
  id: UniqueIdentifier;
}

export const ComparePreviewImage = React.memo<ComparePreviewImageProps>(
  ({ id }) => {
    const data = useRecoilValueLoadable(CompareVariantInfoQuery(id.toString()));
    return data.getValue()?.previewImageUrl ? (
      <img
        className={styles.previewImage}
        src={data.getValue()?.previewImageUrl}
        alt={data.getValue()?.modelName}
      />
    ) : null;
  }
);
