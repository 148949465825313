import React from 'react';
import { HashRouteContext } from './context';

interface LinkProps {
  to: string;
  onClick?: () => void;
  className?: string;
  isRedirectLink?: boolean;
}

export const Link: React.FC<LinkProps> = ({
  to,
  className,
  children,
  onClick,
}) => {
  const { navigate } = React.useContext(HashRouteContext);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (onClick) onClick();

    navigate(to);
  };

  return (
    <a href={`#${to}`} className={className} onClick={handleClick}>
      {children}
    </a>
  );
};
