import React from 'react';
import { LocationSelectedEvent, Suburb } from '../models';
import { setConfiguratorPostcode } from '../services/dxp-postcode-service';

const SUBURB_KEY = 'suburb';

export const usePostcode = () => {
  const [suburub, setSuburb] = React.useState<Suburb>({
    name: 'Sydney',
    fullName: 'Sydney, NSW 2000',
    state: 'NSW',
    postcode: '2000',
  });

  const locationSelected = React.useCallback((event: LocationSelectedEvent) => {
    if (event.detail) {
      const suburb = event.detail?.suburb;

      const suburbData = {
        ...suburb,
        fullName: `${suburb.name}, ${suburb.state} ${suburb.postcode}}`,
      };

      setSuburb(suburbData);
      localStorage.setItem(SUBURB_KEY, JSON.stringify(suburbData));
      setConfiguratorPostcode(suburbData.postcode).then(() => {
        const param = { detail: { suburb: suburbData } };
        window.dispatchEvent(new CustomEvent('dealerChanged', param));
      });
    }
  }, []);

  React.useEffect(() => {
    const suburJson = localStorage.getItem(SUBURB_KEY);
    if (suburJson) setSuburb(JSON.parse(suburJson));
  }, []);

  React.useEffect(() => {
    window.addEventListener('locationSelected', locationSelected);
    return () => {
      window.removeEventListener('locationSelected', () => locationSelected);
    };
  }, [locationSelected]);

  const showPostcodeSelect = () => {
    window.dispatchEvent(new CustomEvent('selectLocation', { detail: {} }));
  };

  return { ...suburub, showPostcodeSelect };
};
