import React from 'react';
import { HashRouteContext } from '../components';

export const useHashRouter = () => {
  const { route, navigate } = React.useContext(HashRouteContext);

  const getParamValue = (name: string) => {
    const match = window.location.href.match(new RegExp(`\A?${name}=[^&]*`));
    const keyValue = match ? match[0] : null;
    return keyValue?.replace(`${name}=`, '') ?? null;
  };

  return { activeRoute: route, navigate, getParamValue };
};
