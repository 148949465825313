import React from 'react';
import cn from 'classnames';
import ContentLoader from 'react-content-loader';
import { usePortals } from 'react-portal-hook';
import { useRecoilValueLoadable, useRecoilRefresher_UNSTABLE } from 'recoil';
import { money } from 'common/utils/formatters';
import { GenericModal } from '~/common/components/generic-modal';
import { getPriceByPostcodeQuery } from 'common/store/user-location-state';
import { DetailedPricing } from '~/pages/showroom/components/price-details/RepaymentDisclaimers';
import styles from './index.module.scss';

interface RdpPriceDetailProps {
  variantId?: string;
  modelName?: string;
  label?: string;
  hideFrom?: boolean;
  className?: string;
}

export const RdpPriceDetail = React.memo<RdpPriceDetailProps>(
  ({ variantId, modelName, hideFrom, label, className }) => {
    const portalManager = usePortals();
    const price = useRecoilValueLoadable(getPriceByPostcodeQuery(variantId));
    const refresh = useRecoilRefresher_UNSTABLE(
      getPriceByPostcodeQuery(variantId)
    );

    const showFinanceDisclaimer = (element: any, title: string) => {
      portalManager.open((portal) => (
        <GenericModal
          title={title}
          element={element}
          closeModal={portal.close}
        />
      ));
    };

    return (
      <div className={cn(styles.priceDetails, className)}>
        <div className={styles.priceDetailsContainer}>
          {!hideFrom && <label>From</label>}
          {price.state === 'loading' ? (
            <div className={styles.priceText}>
              <ContentLoader
                speed={0.3}
                width={155}
                height={55}
                viewBox="0 0 155 55"
                backgroundColor="#aebbd6"
                foregroundColor="#ecebeb"
                style={{ verticalAlign: 'middle' }}
              >
                <rect x="0" y="11" rx="3" ry="3" width="100" height="4" />
                <rect x="0" y="21" rx="3" ry="3" width="50" height="4" />
                <rect x="0" y="45" rx="3" ry="3" width="130" height="4" />
              </ContentLoader>
            </div>
          ) : price.state === 'hasError' ? (
            <div className={cn(styles.priceText, styles.priceTextError)}>
              <p>Sorry, an error occurred loading RDP in your area.</p>
              <p onClick={refresh}>Click here to try again</p>
            </div>
          ) : (
            <div
              onClick={() => {
                showFinanceDisclaimer(
                  DetailedPricing(
                    modelName ?? '',
                    price.contents.rdp,
                    price.contents.mlp
                  ),
                  ''
                );
              }}
            >
              <p id={`rdp-value-${variantId}`} className={styles.priceText}>
                {money(price.contents.rdp, 0)}
              </p>
              <p
                id={`rdp-label-${variantId}`}
                className={cn(styles.priceText2, styles.priceText2_rdp)}
              >
                {label ?? 'Recommended Driveaway Price'}
                <sup>
                  <i className="icon icon--info" />
                </sup>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
);
