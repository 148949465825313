function createQuery(postCode: string) {
  return {
    operationName: 'GetSearchDealer',
    variables: { postCode },
    query: `
      query GetSearchDealer($postCode: String!) {
        searchDealer(postCode: $postCode) {
          id
          externalId
          label
          postCode
          latitude
          longitude
          address {
            address
            name
            city
            geoLevel
            phone
          }
          attributes {
            paymentMerchantId
          }
        }
      }
    `,
  };
}

export const setConfiguratorPostcode = (postCode: string) =>
  fetch(window['DXPGraphQL'], {
    method: 'POST',
    body: JSON.stringify(createQuery(postCode)),
    headers: {
      'Content-Type': 'application/json',
      'x-brand': 'sub-dis',
      'x-country': 'au',
      'x-language': 'en-au',
      'x-region': 'au',
    },
  })
    .then((response) => response.json())
    .then(({ data }) => {
      const jsonData = JSON.stringify({
        postcode: postCode,
        dealerId: data.searchDealer.externalId,
        selectedDealer: data.searchDealer,
      });

      localStorage.setItem('v4_customer_data_', jsonData);
    });
