import React, { useState } from 'react';
import cn from 'classnames';
import { ColourSwatch, InteriorSwatch } from '~/common/components';
import { CompareColourOption, CompareInteriorOption } from '../../models';
import styles from './index.module.scss';

interface VehicleOptionsProps {
  colours: CompareColourOption[];
  interiors: CompareInteriorOption[];
  onSelectOption: (imageUrl: string) => void;
}

export const VehicleOptions = React.memo<VehicleOptionsProps>(
  ({ colours, interiors, onSelectOption }) => {
    const [activeColour, setActiveColour] =
      useState<CompareColourOption | null>(null);
    const [activeInterior, setActiveInterior] =
      useState<CompareInteriorOption | null>(null);

    const handleSelectColour = (colour: CompareColourOption) => {
      setActiveColour(colour);
      onSelectOption(colour.imageUrl);
    };

    const handleSelectInterior = (interior: CompareInteriorOption) => {
      setActiveInterior(interior);
      onSelectOption(interior.assetUrl || interior.imageUrl);
    };

    return (
      <div className={styles.options}>
        <div className={styles.coloursContainer}>
          <div className={styles.optionDetails}>
            <div className={styles.optionTitle}>Colours</div>
            <div className={styles.optionValue}>
              {activeColour?.description || ''}
            </div>
          </div>
          <div className={styles.swatches}>
            {colours.map((colour) => (
              <ColourSwatch
                key={colour.id}
                onClick={() => handleSelectColour(colour)}
                isActive={activeColour?.id === colour.id}
                colour={{
                  colourOptionId: colour.id,
                  frontImageUrl: colour.imageUrl,
                  hexColourCode: colour.hex,
                  name: colour.description,
                }}
              />
            ))}
          </div>
        </div>

        <div className={styles.interiorsContainer}>
          <div className={styles.optionDetails}>
            <div className={styles.optionTitle}>Interiors</div>
            <div className={cn(styles.optionValue, styles.interiorValueMobile)}>
              {activeInterior?.description || ''}
            </div>
          </div>
          <div className={styles.swatches}>
            {interiors.map((interior) => (
              <InteriorSwatch
                key={interior.id}
                onClick={() => handleSelectInterior(interior)}
                isActive={activeInterior?.id === interior.id}
                interior={{
                  interiorOptionId: interior.id,
                  description: interior.description,
                  imageUrl: interior.imageUrl,
                  imageAssets: [interior.assetUrl],
                }}
              />
            ))}
          </div>
          <div className={cn(styles.optionValue, styles.interiorValueDesktop)}>
            {activeInterior?.description || ''}
          </div>
        </div>
      </div>
    );
  }
);
