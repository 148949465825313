import React from 'react';
import { formatMoney } from 'accounting';
import { Modal } from '../ui-elements/modal';
import './index.scss';

interface Props {
  closeModal: () => void;
  modelName: string | undefined;
  rdp: number;
  mlp: number;
}

export const ModelDetailPricing = React.memo<Props>(
  ({ closeModal, modelName, rdp, mlp }) => {
    return (
      <Modal closeModal={closeModal} lockScroll>
        <div className="modelPricingDetails">
          <h1 className="modelName">{modelName}</h1>
          <p className="modelPrice">
            <strong>
              From {formatMoney(rdp, { symbol: '$', precision: 0 })} RDP
              <sup>1</sup>
            </strong>
          </p>
          <p>
            <sup>1</sup> Price is the manufacturer&apos;s recommended retail
            driveaway price (RDP) and includes the vehicle price, 12 months
            registration, stamp duty, luxury car tax (if applicable), CTP
            insurance cost estimate (based on private purchaser with good
            driving record) and a recommended charge for dealer delivery. The
            quoted RDP is an estimate based on the postcode selected by you. RDP
            may change based on location. RDP is not applicable to business or
            government buyers. Final on road costs may vary according to
            individual circumstances and actual driveaway price may vary between
            Retailers. Ask your Retailer for confirmation of their driveaway
            price.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>
              From {formatMoney(mlp, { symbol: '$', precision: 0 })} MLP
              <sup>2</sup>
            </strong>
          </p>
          <p>
            <sup>2</sup> MLP Prices are Manufacturer&apos;s List Price (MLP)
            only and include GST on the list price but exclude dealer delivery
            charges and all other government and statutory charges. MLP&apos;s
            are provided for comparative purposes only and may be subject to
            change. For the drive away price of vehicles see the
            manufacturer&apos;s website or contact your local authorised
            retailer.
          </p>
        </div>
      </Modal>
    );
  }
);
