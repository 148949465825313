import { OptionTypeBase, StylesConfig } from 'react-select';

export const DropdownStyles: StylesConfig<OptionTypeBase, false> = {
  control: (styles) => ({
    ...styles,
    borderWidth: 0,
    boxShadow: 'none',
    ':hover': {
      borderWidth: 0,
      borderColor: 'transparent',
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: 0,
    fontFamily: 'AvenirLT-Medium',
  }),
  singleValue: (styles) => ({
    ...styles,
    fontFamily: 'AvenirLT-Medium',
    fontWeight: 500,
  }),
};
