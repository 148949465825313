import React from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useSetRecoilState, useRecoilValueLoadable } from 'recoil';
import { Select } from '@ui-elements';
import { OptionTypeBase } from 'react-select';
import { replaceItemAtIndex } from '~/common/utils';
import { CompareCarsLocalStorage, GetModelVariantsQuery } from '../../store';
import { CompareCar } from '../../models';
import { DropdownStyles } from './dropdown-style';
import styles from './index.module.scss';

interface VehicleVariantDropdownProps {
  id: UniqueIdentifier;
  modelId: string;
  variantId: string;
}

export const VehicleVariantDropdown = React.memo<VehicleVariantDropdownProps>(
  ({ id, modelId, variantId }) => {
    const setCompareStorage = useSetRecoilState(CompareCarsLocalStorage);

    const variantsOptions = useRecoilValueLoadable(
      GetModelVariantsQuery(modelId)
    );

    const getValue = () => {
      if (variantsOptions.state === 'hasValue') {
        return variantsOptions.getValue().find((v) => v.id === variantId);
      }
    };

    const handleOnChange = (newValue: OptionTypeBase | null) => {
      window['pushDigitalData'](
        {
          event: '_formNavigate',
          form: {
            name: 'compare subaru range',
            stage: 'select cars',
            field: {
              name: 'variant',
              value: newValue?.label,
            },
          },
        },
        true
      );
      if (!!newValue) {
        const variantPimId = newValue.value as string;

        setCompareStorage((items) => {
          const index = items.findIndex((v) => v.id === id);
          return replaceItemAtIndex<CompareCar>(items, index, {
            id: id.toString(),
            modelPimId: modelId,
            variantPimId,
            variant: items[index].variant,
          });
        });
      }
    };

    return (
      <div className={styles.vehicleDropdown}>
        <label>Variant</label>
        {typeof window !== 'undefined' && (
          <Select
            isMulti={false}
            value={getValue()}
            styles={DropdownStyles}
            options={variantsOptions.valueMaybe()}
            isLoading={variantsOptions.state === 'loading'}
            menuPlacement="auto"
            menuPosition="fixed"
            openMenuOnClick
            openMenuOnFocus
            onChange={handleOnChange}
            components={{
              IndicatorSeparator: null,
            }}
            closeMenuOnScroll={(e) => {
              if (e.target === document) {
                return true;
              }
              return false;
            }}
          />
        )}
      </div>
    );
  }
);
