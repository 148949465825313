import React, { useEffect } from 'react';
import { formatNumber } from 'accounting';
import ContentLoader from 'react-content-loader';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { money } from '~/common/utils';
import {
  UserLocationState,
  getRepaymentInfoByPostcodeQuery,
} from '~/common/store';
import styles from './index.module.scss';
import {
  openFinanceModal,
  closeFinanceModal,
} from '~/common/services/finance-service';
import { Button, SvgIcon } from '../ui-elements';

interface GfvDisclaimerProps {
  variantPimId?: string;
}
export const GfvDisclaimer = React.memo<GfvDisclaimerProps>(
  ({ variantPimId }) => {
    const locationState = useRecoilValue(UserLocationState);
    const repaymentInfo = useRecoilValueLoadable(
      getRepaymentInfoByPostcodeQuery(variantPimId)
    );

    const handleShowFinanceModal = () => {
      openFinanceModal(variantPimId, locationState.postcode);
    };

    useEffect(() => {
      const handleCloseModal = () => {
        closeFinanceModal();
      };

      window.addEventListener('closeModal', handleCloseModal);

      return () => {
        window.removeEventListener('closeModal', handleCloseModal);
      };
    }, []);

    return (
      <div className={styles.gfvDisclaimer}>
        <div>
          {repaymentInfo.state === 'loading' ? (
            <ContentLoader
              speed={0.3}
              width={155}
              height={55}
              viewBox="0 0 155 55"
              backgroundColor="#aebbd6"
              foregroundColor="#ecebeb"
              style={{ verticalAlign: 'middle' }}
            >
              <rect x="0" y="11" rx="3" ry="3" width="100" height="4" />
              <rect x="0" y="21" rx="3" ry="3" width="50" height="4" />
              <rect x="0" y="45" rx="3" ry="3" width="130" height="4" />
            </ContentLoader>
          ) : repaymentInfo.contents.gfvNotAvailable ? (
            <p />
          ) : repaymentInfo.contents.financeProductId ===
            'FIN_PROD_AUS_SUB_GFV' ? (
            <p>
              Estimated repayments based on a deposit of{' '}
              {money(repaymentInfo.contents.deposit)} over <br />
              an {repaymentInfo.contents.term} month term with a Guaranteed
              Future Value of {money(repaymentInfo.contents.residualValue)} with
              an annual km allowance of{' '}
              {formatNumber(repaymentInfo.contents.mileage)}.
            </p>
          ) : (
            <p>
              Repayments based on a Consumer Loan with a{' '}
              {money(repaymentInfo.contents.deposit)} deposit over a{' '}
              {repaymentInfo.contents.term} month loan.
            </p>
          )}
        </div>

        <div>
          <Button
            type="text"
            className={styles.editFinanceButton}
            onClick={handleShowFinanceModal}
          >
            <SvgIcon type="pencil" size={0.8} color="#1637a0" />
            <span className={styles.editFinanceButtonText}>
              Edit finance settings
            </span>
          </Button>
        </div>
      </div>
    );
  }
);
