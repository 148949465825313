import React from 'react';
import cn from 'classnames';
import { useRecoilValue } from 'recoil';
import { UserLocationState } from '~/common/store';
import { usePostcodeModal } from '~/common/hooks/use-postcode-modal';
import styles from './index.module.scss';

interface Props {
  className?: string;
}

export const PostcodeDisclaimer = React.memo<Props>((props) => {
  const { showPostcodeModal } = usePostcodeModal();
  const locationState = useRecoilValue(UserLocationState);

  return (
    <div className={cn(styles.pricingDisclaimer, props.className)}>
      <p>
        Recommended Driveaway Price (RDP) shown based on{' '}
        {`${locationState.name}, ${locationState.postcode}`}. RDP may change
        based on location.{' '}
        <span onClick={showPostcodeModal}>Enter your postcode</span> for the RDP
        in your area.
      </p>
    </div>
  );
});
