import React from 'react';
import { formatMoney } from 'accounting';
import styles from './index.module.scss';

export const RepaymentsDisclaimer2 = (
  driveAwayPrice: string,
  loanTerm: string,
  loanRate: number,
  loanDeposit: string,
  loanFrequency: string,
  monthlyRepayment: string,
  isGfv: boolean,
  annualAllowance: string,
  gfvAmount: string
): unknown => {
  return isGfv ? (
    <div className="modelWalk__detailedPricing">
      <p>
        <sup>2</sup> Finance to approved applicants only. These repayments are
        for indicative use only. No result from the use of the calculator should
        be considered a loan application or an offer of a loan.
      </p>
      <p>
        {loanFrequency} repayment amount based on a driveway price of{' '}
        {driveAwayPrice} on a secured Consumer Loan with a {loanTerm} month
        term, a {annualAllowance} annual km allowance, {loanRate}% p.a.
        indicative interest rate with a {loanDeposit} deposit and a Guaranteed
        Future Value of {gfvAmount}.
      </p>
      <p>
        Monthly equivalent repayment is {monthlyRepayment}. Repayments must be
        made fortnightly or monthly and cannot be made weekly. Dealer
        commission, standard fees and charges apply.
      </p>
      <p>
        Before making a decision about any of the products and services featured
        on this website, you should consult with your own independent legal,
        taxation and financial advisors, who can advise you about your personal
        circumstances.
      </p>
    </div>
  ) : (
    <div className="modelWalk__detailedPricing">
      <p>
        <sup>2</sup> Finance to approved applicants only. These repayments are
        for indicative use only. No result from the use of the calculator should
        be considered a loan application or an offer of a loan.
      </p>
      <p>
        {loanFrequency} repayment amount based on a driveway price of{' '}
        {driveAwayPrice} on a secured Consumer Loan with a {loanTerm} month
        term, {loanRate}% p.a. indicative interest rate with a {loanDeposit}{' '}
        deposit.
      </p>
      <p>
        Monthly equivalent repayment is {monthlyRepayment}. Repayments must be
        made fortnightly or monthly and cannot be made weekly. Dealer
        commission, standard fees and charges apply.
      </p>
      <p>
        Before making a decision about any of the products and services featured
        on this website, you should consult with your own independent legal,
        taxation and financial advisors, who can advise you about your personal
        circumstances.
      </p>
    </div>
  );
};

export const DetailedPricing = (
  modelName: string,
  rdp: number,
  mlp: number
): unknown => {
  return (
    <div className="modelWalk__detailedPricing">
      <h1 className={styles.modelName}>{modelName}</h1>
      <p className="modelPrice">
        <strong>
          From {formatMoney(rdp, { symbol: '$', precision: 0 })} RDP
          <sup>1</sup>
        </strong>
      </p>
      <p>
        <sup>1</sup> Price is the manufacturer&apos;s recommended retail
        driveaway price (RDP) and includes the vehicle price, 12 months
        registration, stamp duty, luxury car tax (if applicable), CTP insurance
        cost estimate (based on private purchaser with good driving record) and
        a recommended charge for dealer delivery. The quoted RDP is an estimate
        based on the postcode selected by you. RDP may change based on location.
        RDP is not applicable to business or government buyers. Final on road
        costs may vary according to individual circumstances and actual
        driveaway price may vary between Retailers. Ask your Retailer for
        confirmation of their driveaway price.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          From {formatMoney(mlp, { symbol: '$', precision: 0 })} MLP
          <sup>2</sup>
        </strong>
      </p>
      <p>
        <sup>2</sup> MLP Prices are Manufacturer&apos;s List Price (MLP) only
        and include GST on the list price but exclude dealer delivery charges
        and all other government and statutory charges. MLP&apos;s are provided
        for comparative purposes only and may be subject to change. For the
        drive away price of vehicles see the manufacturer&apos;s website or
        contact your local authorised retailer.
      </p>
    </div>
  );
};

export const RepaymentsDisclaimer4 = (
  isFinanceFormInputPersonalised: boolean
): unknown => {
  return !isFinanceFormInputPersonalised ? (
    <div className="modelWalk__detailedPricing">
      <p>
        <sup>4</sup> The advertised indicative and comparison rate applies to
        home owners aged 23 - 33 as at today&apos;s date. A different rate may
        apply if your circumstances or loan details are different. Rates are
        subject to change without notice.
      </p>
      <p>
        The comparison rate is based on a secured consumer fixed rate loan of
        $30,000 over a term of 5 years.
        <br />
        WARNING: This comparison rate is true only for the examples given and
        may not include all fees and charges. Different terms, fees or other
        loan amounts might result in a different comparison rate. Finance is
        provided by IFSA Pty Ltd ABN 39 651 319 774 trading as Subaru Financial
        Services, managed by Allied Retail Finance Pty Ltd ABN 31 609 859 985
        Australian credit licence 483211.
      </p>
    </div>
  ) : (
    <div className="modelWalk__detailedPricing">
      <p>
        <sup>4</sup> These indicative rates are based on the loan type and the
        details selected. Changing your details or the details of the loan may
        change these rates. Rates are subject to change without notice.
      </p>
      <p>
        The comparison rate is based on a secured consumer fixed rate loan of
        $30,000 over a term of 5 years.
        <br />
        WARNING: This comparison rate is true only for the examples given and
        may not include all fees and charges. Different terms, fees or other
        loan amounts might result in a different comparison rate. Finance is
        provided by IFSA Pty Ltd ABN 39 651 319 774 trading as Subaru Financial
        Services, managed by Allied Retail Finance Pty Ltd ABN 31 609 859 985
        Australian credit licence 483211.
      </p>
    </div>
  );
};

export const RepaymentsDisclaimer1 = (): unknown => {
  return (
    <div className="modelWalk__detailedPricing">
      <p>
        <sup>1</sup> The Guaranteed Future Value (GFV) is the minimum future
        value of your vehicle as determined by Subaru Financial Services (SFS)
        and set out in your contract. At the end of the term, you can select
        from three options: (1) sell or trade in the vehicle and repay your loan
        balance; (2) return the vehicle to us; or (3) retain the vehicle by
        paying the GFV amount, which is a lump sum amount owed to us at the end
        of the loan term. Total interest payable on the loan will be higher than
        a loan with no GFV. Monthly repayments will be lower compared to a
        similar loan term with no GFV or no equivalent balloon final payment.
        Available on new and demonstrator Subaru vehicles for selected models
        only. Vehicle eligibility is subject to change. If you decide to return
        your car at the end of your term, SFS, or another person or entity with
        SFS&apos;s agreement will purchase the vehicle from you for the GFV,
        will be applied to reduce your outstanding loan amount. However, you
        will need to pay us an additional amount if the vehicle is damaged or
        you have travelled excess kilometres. Terms, conditions, fees and
        charges apply. Approved applicants only.
      </p>
    </div>
  );
};

export const RepaymentsDisclaimerForComparisonRate = (): unknown => {
  return (
    <div className="modelWalk__detailedPricing">
      <p>
        <sup>(i)</sup> The comparison rate includes your loan&apos;s interest
        plus other fees and charges relating to your loan - and expresses the
        combined amount as a percentage figure to determine the true cost of
        your loan. Estimates are indicative only. This indicative estimate has
        been provided to assist you with calculating what your repayments may be
        and does not constitute an offer capable of acceptance. Rates are
        subject to the accuracy of the information provided by you, the outcome
        of the assessment of your application, and changes in base rates. Any
        changes to the loan details may result in a different interest rate. The
        actual rate applied and repayment amount will be confirmed in the
        finance documentation if your application is approved. Fees and charges
        are payable. Full terms and conditions are available on request.
      </p>
    </div>
  );
};
