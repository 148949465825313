import React, { useState, useEffect, useRef, useCallback } from 'react';
import cn from 'classnames';
import {
  IntentSlideUpBoxModel,
  IntentCtaBlock,
} from '~/pages/model-landing/store';
import {
  GetSessionIntentHide,
  SetSessionIntentHide,
} from '~/common/utils/session';
import { Button, SvgIcon } from '~/common/components/ui-elements';
import { useIsMobile } from '~/common/hooks/use-is-mobile';
import styles from './index.module.scss';
import digitalData from '~/common/services/data-layer-service';
import { Link } from '~/common/models';

interface IntentProps {
  intentBoxContent?: IntentSlideUpBoxModel;
  isActive: boolean;
  modelName?: string;
}

export const IntentBar = React.memo<IntentProps>(
  ({ intentBoxContent, isActive, modelName }) => {
    const [isIntentBarVisible, setIntentBarVisible] = useState<boolean>(
      isActive || false
    );
    const isIntentHidden = GetSessionIntentHide() === 1 ? true : false;
    const [isIntentBarClosed, setIntentBarClosed] =
      useState<boolean>(isIntentHidden);
    const { isMobileOrSmallTablet } = useIsMobile();

    const hideIntentBar = () => {
      SetSessionIntentHide();
      document.body.style.removeProperty('overflow');
      document.documentElement.style.removeProperty('overflow');

      if (isIntentBarVisible) {
        setIntentBarVisible(false);
        setIntentBarClosed(GetSessionIntentHide() === 1 ? true : false);
      }
    };

    useEffect(() => {
      if (isIntentBarClosed) {
        document.body.style.removeProperty('overflow');
        document.documentElement.style.removeProperty('overflow');
        setIntentBarVisible(false);
      } else {
        if (isActive) {
          document.body.style.overflow = 'hidden';
          document.documentElement.style.overflow = 'hidden';
        }
        setIntentBarVisible(isActive);
      }
    }, [isActive]);

    return (
      <>
        <div
          className={cn(
            styles.intentSlideUpBarSection,
            isIntentBarVisible ? styles.intentVisible : styles.intentHidden
          )}
        >
          <div
            onClick={hideIntentBar}
            className={styles.intentOverlay}
            style={
              isActive && !isIntentBarClosed
                ? {
                    visibility: 'visible',
                    opacity: 1,
                  }
                : {}
            }
          />
          <div
            className={styles.intentContent}
            style={
              isActive && !isIntentBarClosed
                ? {
                    visibility: 'visible',
                    opacity: 1,
                  }
                : {}
            }
          >
            <div className={styles.intentCloseButton} onClick={hideIntentBar}>
              <SvgIcon
                type="close"
                color="#032660"
                size={isMobileOrSmallTablet() ? '18px' : '30px'}
              />
            </div>
            <div className={styles.intentHeader}>
              <span
                dangerouslySetInnerHTML={{
                  __html: intentBoxContent?.intentTitle || '',
                }}
              />
            </div>
            <div className={styles.intentDescription}>
              {intentBoxContent?.intentDescription}
            </div>
            {intentBoxContent?.intentCtaBlocks &&
              intentBoxContent?.intentCtaBlocks.length > 0 && (
                <div className={styles.intentCtasSection}>
                  {intentBoxContent?.intentCtaBlocks
                    ?.slice(0, 3)
                    .map((currCta, index) => (
                      <IntentCTA
                        key={index}
                        modelName={modelName}
                        {...currCta}
                      />
                    ))}
                </div>
              )}
          </div>
        </div>
      </>
    );
  }
);

interface IntentCTAProps extends IntentCtaBlock {
  modelName?: string;
}

export const IntentCTA = React.memo<IntentCTAProps>(({ ...props }) => {
  const handleOnClick = (ctaDetails: Link | undefined, modelName?: string) => {
    if (ctaDetails?.name.toLocaleLowerCase() === 'download a brochure') {
      digitalData.push({
        event: '_formNavigate',
        form: {
          name: 'download a brochure',
          stage: 'submitted',
          details: {
            vehicleSelected: [
              {
                make: 'subaru',
                model: modelName,
              },
            ],
          },
        },
      });
    }

    window.open(ctaDetails?.url, ctaDetails?.target || '_self');
  };
  const { isMobileOrSmallTablet } = useIsMobile();

  return (
    <div
      className={styles.IntentCtaContainer}
      onClick={() => handleOnClick(props?.intentCtadetails, props?.modelName)}
    >
      <div className={styles.IntentCtaIconContainer}>
        <img
          className={cn(styles.IntentCtaIcon, styles.CtaIconDesktop)}
          src={props?.ctaIconImage}
        />
        <img
          className={cn(styles.IntentCtaIcon, styles.CtaIconMobile)}
          src={props?.ctaIconImageMobile}
        />
      </div>
      <div className={styles.IntentCtaButtonContainer}>
        {isMobileOrSmallTablet() ? (
          <span className={styles.intetnCtaNameMobile}>
            {props?.intentCtadetails?.name}
          </span>
        ) : (
          <Button
            href={`${props?.intentCtadetails?.url}`}
            type={'primary'}
            text={props?.intentCtadetails?.name}
            block
            target={props?.intentCtadetails?.target || '_self'}
            buttonWidth="full-width"
          />
        )}
      </div>
    </div>
  );
});
