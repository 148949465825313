import React, { useState, useEffect, useRef } from 'react';
import { usePopper } from 'react-popper';
import { Button, SvgIcon } from '@ui-elements';
import { useRecoilValue } from 'recoil';
import { CompareHeadingState } from '../../store';
import { useHashRouter } from '~/common/hooks/use-hashrouter';
import styles from './index.module.scss';

export const CompareHeading = React.memo(() => {
  const router = useHashRouter();
  const [returnUrl, setReturnUrl] = useState<string | null>();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const { headerTitle, headerDescription, headerTooltip } =
    useRecoilValue(CompareHeadingState);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [referenceElement, setReferenceElement] =
    useState<HTMLElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>();
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>();
  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
      placement: 'auto-end',
    }
  );

  const showTooltip = () => {
    popperElement?.setAttribute('data-show', 'true');
  };

  const hideTooltip = () => {
    popperElement?.removeAttribute('data-show');
  };

  useEffect(() => {
    const param = router.getParamValue('returnUrl');
    setReturnUrl(param);
  }, []);

  useEffect(() => {
    if (containerRef.current === null) {
      setIsMobile(false);
    } else if (containerRef.current?.clientWidth < 923) {
      setIsMobile(true);
    }
  }, []);

  return (
    <div ref={containerRef} className={styles.compareHeading}>
      <div className={styles.ctaButtonCompare}>
        {!!returnUrl &&
          (isMobile ? (
            <div>
              <a href={returnUrl}>
                <SvgIcon type="chevronLeft" color="#1637A0" />
                <span>Back to previous page</span>
              </a>
            </div>
          ) : (
            <div>
              <Button type="primary" href={returnUrl}>
                <SvgIcon type="chevronLeft" size={1} color="#FFFFFF" />
                Back to previous page
              </Button>
            </div>
          ))}
      </div>

      <div className={styles.headerTitles}>
        <div className={styles.compareHeadingTitle}>
          <h1
            className={styles.compareHeadingTitleText}
            dangerouslySetInnerHTML={{ __html: headerTitle }}
          />

          {!!headerTooltip && (
            <>
              <span
                ref={setReferenceElement}
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
                className={styles.disclaimerTrigger}
              >
                *
              </span>
              <div
                ref={setPopperElement}
                style={popperStyles.popper}
                {...attributes.popper}
                className={styles.disclaimerTooltip}
              >
                <div dangerouslySetInnerHTML={{ __html: headerTooltip }} />
                <div ref={setArrowElement} style={popperStyles.arrow} />
              </div>
            </>
          )}
        </div>

        <div
          className={styles.compareHeadingDescription}
          dangerouslySetInnerHTML={{
            __html: headerDescription as string,
          }}
        />
      </div>
    </div>
  );
});
