import React from 'react';
import { RdpPriceDetail, PostcodeDisclaimer } from '~/common/components';
import styles from './index.module.scss';

interface VariantFinanceProp {
  variantId: string;
  modelName: string;
  legacyId: string;
}
export const VariantFinance = React.memo<VariantFinanceProp>(
  ({ modelName, legacyId }) => {
    return (
      <div className={styles.variantFinance}>
        <div className={styles.pricingInfo}>
          <RdpPriceDetail
            modelName={modelName}
            variantId={legacyId}
            label="Recommended Driveaway Price"
            className={styles.variantRdp}
          />
        </div>

        <PostcodeDisclaimer />
      </div>
    );
  }
);
