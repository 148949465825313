import React, { useEffect } from 'react';
import { HashRouteContext } from './context';

type RouteProps = {
  path: string;
  redirectTo: string;
};

type Props = {
  routes: RouteProps[];
  children: JSX.Element | JSX.Element[];
};

export const RedirectContainer: React.FC<Props> = ({ routes, children }) => {
  const { navigate } = React.useContext(HashRouteContext);
  useEffect(() => {
    if (window && typeof window !== 'undefined') {
      const idx = routes.findIndex((x) => x.path === window.location.hash);
      if (idx >= 0) {
        setTimeout(() => navigate(routes[idx].redirectTo), 0);
      }
    }
  }, []);
  return <>{children}</>;
};
