import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import cn from 'classnames';
import {
  AccordionItem,
  FloatingDisclaimer,
  TooltipDisclaimer,
} from '@ui-elements';
import { replaceItemAtIndex } from '~/common/utils';
import { CompareSpecGroupState, CompareHeadingState } from '../../store';
import { CompareSpecGroup, CompareVariantSpec, SpecsTitle } from '../../models';
import styles from './index.module.scss';

interface VehicleSpecsFeatProp {
  specs: CompareVariantSpec[];
}

export const VehicleSpecsFeat = React.memo<VehicleSpecsFeatProp>(
  ({ specs }) => {
    const groups = useRecoilValue(CompareSpecGroupState);
    const { specsTooltip, featuresTooltip } =
      useRecoilValue(CompareHeadingState);

    const groupTypes = React.useMemo(() => {
      return groups.reduce<string[]>((gt, next) => {
        return gt.includes(next.groupType) ? gt : [...gt, next.groupType];
      }, []);
    }, [groups]);

    return (
      <div className={styles.specsWrapper}>
        {groupTypes.map((type, idx) => {
          return (
            <div key={type}>
              <div className={styles.specsFeaturesGroup}>
                <h3>{type}</h3>
                <TooltipDisclaimer
                  disclaimer={idx === 0 ? specsTooltip : featuresTooltip}
                  className={styles.specsFeaturesGroupTooltip}
                />
              </div>
              {groups
                .filter((group) => group.groupType === type)
                .map((group) => {
                  const index = groups.findIndex(
                    (item) => item.id === group.id
                  );
                  return (
                    <CompareSpecGroupAccordion
                      key={index}
                      index={index}
                      group={group}
                      groups={groups}
                    >
                      <CompareSpecsItemList group={group} specs={specs} />
                    </CompareSpecGroupAccordion>
                  );
                })}
            </div>
          );
        })}
      </div>
    );
  }
);

interface CompareSpecGroupAccordionProps {
  index: number;
  groups: CompareSpecGroup[];
  group: CompareSpecGroup;
}
const CompareSpecGroupAccordion = React.memo<
  PropsWithChildren<CompareSpecGroupAccordionProps>
>(({ groups, index, group, children }) => {
  const setCompareGroups = useSetRecoilState(CompareSpecGroupState);
  const updateGroupExpand = (expanded: boolean) => {
    const newGroup = { ...group, expanded };
    const newGroups = replaceItemAtIndex(groups, index, newGroup);
    setCompareGroups(newGroups);
  };

  return (
    <div className={styles.groupWrapper}>
      <AccordionItem
        key={group.id}
        title={group.groupName}
        expanded={group.expanded}
        className={styles.groupItem}
        titleClass={styles.groupTitle}
        contentClass={styles.groupContent}
        onExpandChange={updateGroupExpand}
      >
        {children}
      </AccordionItem>
    </div>
  );
});

interface CompareSpecsItemListProp extends VehicleSpecsFeatProp {
  group: CompareSpecGroup;
}
const CompareSpecsItemList = React.memo<CompareSpecsItemListProp>(
  ({ group, specs }) => {
    const [variantSpecs, setVariantSpecs] = useState<
      Record<string, CompareVariantSpec | undefined>
    >({});

    useEffect(() => {
      group.specsTitles.forEach((specTitle) => {
        const data = specs.find(
          (spec) =>
            spec.title.toLowerCase() === specTitle.title.toLowerCase() &&
            spec.groupName.toLowerCase() === group.groupName.toLowerCase()
        );

        setVariantSpecs((obj) => ({
          ...obj,
          [specTitle.title.toLowerCase()]: data,
        }));
      });
    }, [group]);

    return (
      <div className={styles.specsItemList}>
        {group.specsTitles.map((specTitle, index) => (
          <SpecItem
            key={index}
            index={index}
            specTitle={specTitle}
            spec={variantSpecs[specTitle.title.toLowerCase()]}
          />
        ))}
      </div>
    );
  }
);

interface SpecItemProps {
  index: number;
  specTitle: SpecsTitle;
  spec?: CompareVariantSpec;
}
const SpecItem = React.memo<SpecItemProps>(({ index, specTitle, spec }) => {
  const updatedValue = () => {
    let value = spec?.value;
    if (!spec?.value || spec?.value === 'n/a' || spec?.value === '-')
      value = '-';
    return value;
  };

  const calculateHeight = () => {
    const lineHeight = 20;
    const characterLength = getPredictedLengths();
    const length = specTitle.longValue?.length || 0;
    return `${Math.ceil(length / characterLength) * lineHeight}px`;
  };

  const getPredictedLengths = () => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 321) return 17;
      if (window.innerWidth < 376) return 20;
      if (window.innerWidth < 426) return 25;
    }
    return 40;
  };

  const withIndent = React.useMemo(
    () => specTitle.title.includes('+'),
    [specTitle.title]
  );

  return (
    <div className={cn(styles.specsItem, index % 2 === 0 && styles.bgGrey)}>
      <FloatingDisclaimer
        disclaimer={specTitle.disclaimer}
        triggerClassName={styles.trigger}
        className={styles.description}
      >
        {({ renderTrigger }) => (
          <>
            <label>
              {withIndent ? (
                <span className={styles.specValueIndent}>
                  <span>&#x2022;</span>
                  <span>
                    {specTitle.title.substring(1).trim()}
                    {renderTrigger()}
                  </span>
                </span>
              ) : (
                <span>
                  {specTitle.title}
                  {renderTrigger()}
                </span>
              )}
            </label>
          </>
        )}
      </FloatingDisclaimer>
      <label
        className={styles.value}
        style={{ minHeight: calculateHeight() }}
      >{`${updatedValue()}`}</label>
    </div>
  );
});
