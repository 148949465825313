import React from 'react';
import { usePostcode } from '~/common/hooks/use-postcode';
import { Suburb } from '~/common/models';
import styles from './index.module.scss';

interface PostcodeSelectProps {
  onPostcodeChange?: (postcode: string) => void;
  children?: (
    suburb: Suburb,
    showPostcodeSelect: () => void
  ) => React.ReactNode;
}

export const PostcodeSelect = React.memo<PostcodeSelectProps>(
  ({ onPostcodeChange, children }) => {
    const { postcode, state, name, fullName, showPostcodeSelect } =
      usePostcode();

    React.useEffect(() => {
      if (onPostcodeChange) onPostcodeChange(postcode);
    }, [postcode]);

    return (
      <div className={styles.PostcodeSelect}>
        {children ? (
          children({ postcode, state, name, fullName }, showPostcodeSelect)
        ) : (
          <label>
            Postcode {postcode}
            <span onClick={showPostcodeSelect}>Change</span>
          </label>
        )}
      </div>
    );
  }
);
