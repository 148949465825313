import React, { useImperativeHandle, useEffect } from 'react';
import cn from 'classnames';
import { SvgIcon } from '@ui-elements';
import styles from './index.module.scss';

interface VerticalCarouselProps {
  itemHeight: number;
  renderItems: number;
  showArrows?: boolean;
  children?: React.ReactNode;
  onChange?: (index: number) => void;
}

export type VerticalCarouselRef = {
  moveToPage: (page: number) => void;
};

export const VerticalCarousel = React.forwardRef<
  VerticalCarouselRef | undefined,
  VerticalCarouselProps
>(({ children, renderItems, itemHeight, showArrows, onChange }, ref) => {
  const carouselRef = React.useRef<HTMLDivElement | null>(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [carouselItems, setCarouselItems] = React.useState<Element[]>([]);

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);

      if (!!onChange) onChange(newIndex);
    }
  };

  const handleNextClick = () => {
    if (currentIndex + renderItems < carouselItems.length) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);

      if (!!onChange) onChange(newIndex);
    }
  };

  const moveToPage = (page: number) => {
    setCurrentIndex(page);
  };

  useImperativeHandle(ref, () => ({
    moveToPage,
  }));

  useEffect(() => {
    if (!!carouselRef.current) {
      const elements: Element[] = [];
      for (const el of carouselRef.current.children) {
        const element = el as HTMLElement;
        element.style.height = `${itemHeight}px`;
        elements.push(element);
      }

      setCarouselItems(elements);
    }
  }, [children]);

  return (
    <div className={styles.verticalCarousel}>
      {showArrows && (
        <button
          onClick={handlePrevClick}
          className={cn(styles.arrow, {
            [styles.arrowDisabled]: currentIndex === 0,
          })}
        >
          <SvgIcon type="chevronUp" />
        </button>
      )}
      <div className={styles.carouselContainer}>
        <div
          ref={carouselRef}
          className={styles.carousel}
          style={{
            transform: `translateY(-${currentIndex * itemHeight}px)`,
            height: `${
              (renderItems > carouselItems.length
                ? carouselItems.length
                : renderItems) * itemHeight
            }px`,
          }}
        >
          {children}
        </div>
      </div>
      {showArrows && (
        <button
          className={cn(styles.arrow, {
            [styles.arrowDisabled]:
              currentIndex + renderItems === carouselItems.length,
          })}
          onClick={handleNextClick}
        >
          <SvgIcon type="chevronDown" />
        </button>
      )}
    </div>
  );
});
