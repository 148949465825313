import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  Active,
  UniqueIdentifier,
  MeasuringStrategy,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { SortableOverlay } from './sortable-overlay';
import styles from './sortable-list.module.scss';

export interface SortableListBaseItem {
  id: UniqueIdentifier;
}

interface Props<T extends SortableListBaseItem> {
  items: T[];
  onChange(items: T[]): void;
  renderItem(item: T): React.ReactNode;
  className?: string;
  itemContainerRef?: (node: HTMLElement | null) => void;
}

export function SortableList<T extends SortableListBaseItem>({
  items,
  onChange,
  renderItem,
  className,
  itemContainerRef,
}: Props<T>) {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items]
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      onDragStart={({ active: activeStart }) => {
        setActive(activeStart);
      }}
      onDragEnd={({ active: activeStart, over }) => {
        if (over && activeStart.id !== over?.id) {
          const activeIndex = items.findIndex(
            ({ id }) => id === activeStart.id
          );
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={items}>
        <ul
          ref={itemContainerRef}
          className={cn(styles.sortableList, className)}
          role="application"
        >
          {items.map((item) => (
            <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
          ))}
        </ul>
      </SortableContext>
      <SortableOverlay>
        {activeItem ? renderItem(activeItem) : null}
      </SortableOverlay>
    </DndContext>
  );
}
