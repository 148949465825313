import React, {
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { UniqueIdentifier } from '@dnd-kit/core';
import { SvgIcon } from '@ui-elements';
import { SortableList, SortableItem } from '~/common/components';
import { CompareCard } from '../compare-card';
import { CompareSticky } from '../compare-sticky';
import { CompareCarsLocalStorage, CompareSpecGroupState } from '../../store';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { getSpecsGroup } from '../../utils';
import { useIsMobile } from '~/common/hooks/use-is-mobile';
import { useIsTablet } from '~/common/hooks/use-is-tablet';
import styles from './index.module.scss';

export const CompareGrid = React.memo(() => {
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const compareGridRef = useRef<HTMLDivElement | null>(null);
  const [arrowTranslateY, setArrowTranslateY] = useState<number>(0);
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(false);
  const [compareStickyRef, setCompareStickyRef] =
    useState<HTMLElement | null>();
  const [scrollContainer, setContainerRef] = useState<HTMLElement | null>();
  const [compareCars, setCompareCars] = useRecoilState(CompareCarsLocalStorage);
  const setSpecGroup = useSetRecoilState(CompareSpecGroupState);

  const handleRemove = (id: UniqueIdentifier) => {
    setCompareCars((items) => items.filter((item) => item.id !== id));
    updateScrollState();
  };

  const getScrollWidth = () => {
    return isMobile() ? (window.innerWidth + 10) / 2 : 415;
  };

  const handleBack = () => {
    if (!!scrollContainer) scrollContainer.scrollLeft -= getScrollWidth();
    if (!!compareStickyRef) compareStickyRef.scrollLeft -= getScrollWidth();
    updateScrollState();
  };

  const handleNext = () => {
    if (!!scrollContainer) scrollContainer.scrollLeft += getScrollWidth();
    if (!!compareStickyRef) compareStickyRef.scrollLeft += getScrollWidth();
    updateScrollState();
  };

  const updateScrollState = () => {
    if (!!scrollContainer) {
      const t = setTimeout(() => {
        const scrollLeft = scrollContainer.scrollLeft;
        const scrollWidth = scrollContainer.scrollWidth;
        const containerWidth = scrollContainer.clientWidth;
        setCanScrollLeft(scrollLeft !== 0);
        setCanScrollRight(scrollLeft + containerWidth < scrollWidth);
        clearTimeout(t);
      }, 400);
    }
  };

  const handleScrollChange = () => {
    if (!!compareGridRef.current) {
      const currentY = window.scrollY;
      const gridHeight = compareGridRef.current.clientHeight;

      const compareHeight = (gridHeight - 400) / 2;
      const translateY = (compareHeight - currentY) * -1;
      if (translateY > -compareHeight && translateY < compareHeight - 200) {
        setArrowTranslateY(translateY);
      }
    }
  };

  useEffect(updateScrollState, [scrollContainer, compareCars]);

  // update specs group based on selected vehicle variants
  useEffect(
    () => setSpecGroup(getSpecsGroup(compareCars, !(isMobile() || isTablet()))),
    [compareCars]
  );

  useLayoutEffect(handleScrollChange, [compareGridRef.current?.clientHeight]);

  useEffect(() => {
    window.addEventListener('scroll', handleScrollChange);
    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);

  return (
    <div ref={compareGridRef} className={styles.compareGrid}>
      <CompareSticky ref={setCompareStickyRef} />

      <CarouselButton
        translateY={arrowTranslateY}
        className={styles.scrollLeft}
        enabled={canScrollLeft}
        onClick={handleBack}
      >
        <SvgIcon type="chevronLeft" size={2} />
      </CarouselButton>
      <SortableList
        items={compareCars}
        onChange={setCompareCars}
        className={styles.horizontalGrid}
        itemContainerRef={setContainerRef}
        renderItem={(item) => (
          <SortableItem id={item.id} className={styles.compareCarItem}>
            <CompareCard
              id={item.id}
              modelId={item.modelPimId}
              variantId={item.variantPimId}
              onRemove={handleRemove}
            />
          </SortableItem>
        )}
      />
      <CarouselButton
        translateY={arrowTranslateY}
        className={styles.scrollRight}
        enabled={canScrollRight}
        onClick={handleNext}
      >
        <SvgIcon type="chevronRight" size={2} />
      </CarouselButton>

      <div className={styles.mobileArrows}>
        <div>
          <button
            onClick={canScrollLeft ? handleBack : undefined}
            className={cn(!canScrollLeft && styles.arrowDisabled)}
          >
            <SvgIcon type="chevronLeft" size={1.2} />
          </button>
        </div>
        <div>
          <button
            onClick={canScrollRight ? handleNext : undefined}
            className={cn(!canScrollRight && styles.arrowDisabled)}
          >
            <SvgIcon type="chevronRight" size={1.2} />
          </button>
        </div>
      </div>
    </div>
  );
});

interface CarouselButtonProps {
  enabled: boolean;
  onClick: () => void;
  translateY: number;
  className?: string;
}
const CarouselButton = React.memo<PropsWithChildren<CarouselButtonProps>>(
  ({ enabled, onClick, children, className, translateY }) => (
    <div
      className={cn(styles.scrollButtons, className)}
      style={{ transform: `translateY(${translateY}px)` }}
    >
      {
        <button
          onClick={enabled ? onClick : undefined}
          className={cn(!enabled && styles.arrowDisabled)}
        >
          {children}
        </button>
      }
    </div>
  )
);
