import React from 'react';
import { usePortals } from 'react-portal-hook';
import { PostcodeModal } from '../components';

export const usePostcodeModal = () => {
  const portalManager = usePortals();

  const showPostcodeModal = () => {
    portalManager.open((portal) => <PostcodeModal closeModal={portal.close} />);
  };

  return { portalManager, showPostcodeModal };
};
