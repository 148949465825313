import React, {
  createContext,
  useContext,
  useMemo,
  CSSProperties,
  PropsWithChildren,
} from 'react';
import cn from 'classnames';
import { DraggableSyntheticListeners, UniqueIdentifier } from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styles from './index.module.scss';

interface Props {
  id: UniqueIdentifier;
  className?: string;
  style?: CSSProperties;
}

interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {},
});

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true, isSorting: true });

export const SortableItem = React.memo<PropsWithChildren<Props>>(
  ({ id, children, className, style }) => {
    const {
      attributes,
      isDragging,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
    } = useSortable({ id, animateLayoutChanges });

    const context = useMemo(
      () => ({
        attributes,
        listeners,
        ref: setActivatorNodeRef,
      }),
      [attributes, listeners, setActivatorNodeRef]
    );

    const newStyle: CSSProperties = {
      ...style,
      opacity: isDragging ? 0.4 : undefined,
      transform: CSS.Translate.toString(transform),
      transition,
    };

    return (
      <SortableItemContext.Provider value={context}>
        <li
          className={cn(
            className,
            styles.sortableItem,
            isDragging && styles.dragging
          )}
          ref={setNodeRef}
          style={newStyle}
        >
          {children}
        </li>
      </SortableItemContext.Provider>
    );
  }
);

export const SortableDragHandle = React.memo(() => {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <button
      className={styles.dragHandle}
      {...attributes}
      {...listeners}
      ref={ref}
    >
      <svg viewBox="0 0 20 20" width="12">
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z" />
      </svg>
    </button>
  );
});
