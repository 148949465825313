import React, { CSSProperties } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface Props {
  id?: string;
  src?: string;
  alt?: string;
  height?: string;
  children?: React.ReactNode;
  contentClass?: string;
  className?: string;
  imageClass?: string;
  style?: CSSProperties | undefined;
  withClientHeader?: boolean;
  mediaContent?: () => JSX.Element;
}

export const PageHero = React.memo<Props>(
  ({
    id,
    src,
    alt,
    height,
    children,
    imageClass,
    className,
    contentClass,
    withClientHeader,
    style,
    mediaContent,
  }) => (
    <div id={id} className={cn(styles.pageHero, className)} style={style}>
      {!!mediaContent ? (
        mediaContent()
      ) : (
        <img
          src={src}
          alt={alt}
          loading="lazy"
          style={{ width: '100%', ...(height ? { height } : {}) }}
          className={cn(styles.pageHeroImage, imageClass, {
            [styles.withClientHeader]: withClientHeader,
          })}
        />
      )}

      {children && (
        <div
          className={cn(styles.pageHeroContent, contentClass, {
            [styles.withClientHeader]: withClientHeader,
          })}
        >
          {children}
        </div>
      )}
    </div>
  )
);
