import _ from 'lodash';

export const IncrementSessionPageVisited = (): void => {
  const sessionPageVisited = sessionStorage.getItem('sessionPageVisited');
  let visitedCount = sessionPageVisited ? _.parseInt(sessionPageVisited) : 0;
  visitedCount = visitedCount + 1;
  sessionStorage.setItem('sessionPageVisited', visitedCount.toString());
};

export const GetSessionPageVisited = (): number => {
  const sessionPageVisited = sessionStorage.getItem('sessionPageVisited');
  return sessionPageVisited ? _.parseInt(sessionPageVisited) : 0;
};

export const GetSessionPopupHide = (): number => {
  const sessionPopupHide = sessionStorage.getItem('sessionPopupHide');
  return sessionPopupHide ? _.parseInt(sessionPopupHide) : 0;
};

export const SetSessionPopupHide = (): void => {
  sessionStorage.setItem('sessionPopupHide', '1');
};

export const GetSessionIntentHide = (): number => {
  const sessionIntentHide = sessionStorage.getItem('sessionIntentHide');
  return sessionIntentHide ? _.parseInt(sessionIntentHide) : 0;
};

export const SetSessionIntentHide = (): void => {
  sessionStorage.setItem('sessionIntentHide', '1');
};

export const GetLocalHasSubscribed = (): number => {
  const hasSubscribed = sessionStorage.getItem('hasSubscribed');
  return hasSubscribed ? _.parseInt(hasSubscribed) : 0;
};
