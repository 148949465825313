import uuid from 'react-uuid';
import {
  CompareCar,
  CompareSpecGroup,
  CompareVariantSpec,
  SpecsTitle,
} from './models';

export const addSpare = (items: CompareCar[]) => {
  const newItems: CompareCar[] = [...items];

  const count = items.length;
  if (count === 0) {
    newItems.push(
      {
        id: uuid(),
        modelPimId: '',
        variantPimId: '',
      },
      {
        id: uuid(),
        modelPimId: '',
        variantPimId: '',
      },
      {
        id: uuid(),
        modelPimId: '',
        variantPimId: '',
      }
    );

    return newItems;
  }

  if (count < 3) {
    for (let index = count; index < 3; index++) {
      newItems.push({
        id: uuid(),
        modelPimId: '',
        variantPimId: '',
      });
    }

    return newItems;
  }

  const lastItem = items[count - 1];
  if (!newItems.some((c) => noVehicleSelected(c))) {
    if (!noVehicleSelected(lastItem)) {
      newItems.push({
        id: uuid(),
        modelPimId: '',
        variantPimId: '',
      });
    }
  }

  return sortArrayWithEmptyValuesLast(newItems);
};

export function noVehicleSelected(compareCar: CompareCar) {
  return isEmpty(compareCar.modelPimId) || isEmpty(compareCar.variantPimId);
}

function isEmpty(value?: string) {
  return value?.length === 0;
}

function sortArrayWithEmptyValuesLast(compareCars: CompareCar[]): CompareCar[] {
  const selected = compareCars.filter((x) => !noVehicleSelected(x));

  if (selected.length >= 2) {
    return compareCars.sort((a, b) => {
      const isEmptyA = a.modelPimId === '' && a.variantPimId === '';
      const isEmptyB = b.modelPimId === '' && b.variantPimId === '';

      if (isEmptyA && isEmptyB) {
        return 0; // Both are empty, no change in order
      } else if (isEmptyA) {
        return 1; // A is empty, move it to the end
      } else if (isEmptyB) {
        return -1; // B is empty, move it to the end
      }

      return 0;
    });
  }

  return compareCars;
}

export function getSpecsGroup(compareCars: CompareCar[], isExpanded: boolean) {
  const allSpecs = compareCars
    .filter((c) => !!c.variant)
    .map((c) => c.variant)
    .flatMap((v) => v?.specs || []);

  const uniqueGroups = getUniqueGroups(sortSpecsGroups(allSpecs)).map(
    (spec) =>
      ({
        id: spec.id,
        displayOrder: spec.groupDisplayOrder,
        expanded: isExpanded,
        groupName: spec.groupName,
        groupType: spec.groupType,
      } as CompareSpecGroup)
  );

  uniqueGroups.forEach((group) => {
    group.specsTitles = getUniqueSpecs(
      allSpecs
        .filter((spec) => spec.groupName === group.groupName)
        .sort((g) => g.specDisplayOrder)
        .map((g) => ({ title: g.title } as SpecsTitle))
    );

    group.specsTitles.forEach((specTitle) => {
      const specDisclaimer = allSpecs.find(
        (s) =>
          s.groupName === group.groupName &&
          s.title === specTitle.title &&
          !!s.disclaimer
      );

      if (!!specDisclaimer) {
        specTitle.disclaimer = specDisclaimer.disclaimer;
      }

      specTitle.longValue = findLongestByValue(
        group.groupName,
        specTitle.title,
        allSpecs
      );
    });
  });

  return uniqueGroups;
}

function sortSpecsGroups(compareCars: CompareVariantSpec[]) {
  const groupedObjects = {};

  compareCars.forEach((obj) => {
    if (!groupedObjects[obj.groupType]) {
      groupedObjects[obj.groupType] = [];
    }

    groupedObjects[obj.groupType].push(obj);
  });

  for (const groupType in groupedObjects) {
    if (groupedObjects.hasOwnProperty(groupType)) {
      groupedObjects[groupType].sort(
        (a: CompareVariantSpec, b: CompareVariantSpec) =>
          a.groupDisplayOrder - b.groupDisplayOrder
      );
    }
  }

  return Object.values<CompareVariantSpec>(groupedObjects).flat();
}

function getUniqueGroups(specsItems: CompareVariantSpec[]) {
  return specsItems.filter((value, index, self) => {
    return self.findIndex((obj) => obj.groupName === value.groupName) === index;
  });
}

function getUniqueSpecs(specsItems: SpecsTitle[]) {
  return specsItems.filter((value, index, self) => {
    return (
      self.findIndex(
        (obj) => obj.title.toLowerCase() === value.title.toLocaleLowerCase()
      ) === index
    );
  });
}

function findLongestByValue(
  groupName: string,
  specTitle: string,
  allSpecs: CompareVariantSpec[]
) {
  const spec = allSpecs.filter(
    (s) => s.groupName === groupName && s.title === specTitle
  );

  return spec.reduce(
    (longest, current) =>
      current.value.length > longest.length ? current.value : longest,
    ''
  );
}
