import React from 'react';
import { Button, SvgIcon } from '@ui-elements';
import styles from './index.module.scss';

export const BrochureAndDealer = React.memo(() => (
  <div className={styles.BrocAndDlr}>
    <div className="container mx-auto">
      <div className={styles.BrocAndDlr_inner}>
        <div className={styles.CTA}>
          <Button
            type="text"
            className={styles.CTA_btn}
            href="/brochure-download"
          >
            <span className={styles.CTA_text}>Download Brochure</span>
            <SvgIcon type="chevronRight" size={1} />
          </Button>
          <p>Learn more about our models in your own time</p>
        </div>

        <div className={styles.CTA}>
          <Button type="text" className={styles.CTA_btn} href="/dealers">
            <span className={styles.CTA_text}>Find a Retailer</span>
            <SvgIcon type="chevronRight" size={1} />
          </Button>
          <p>Get in touch with your nearest Subaru retailer</p>
        </div>
      </div>
    </div>
  </div>
));
