import React from 'react';
import {
  AnimatedNumberIcon,
  CallToActionIcon,
  ImageIcon,
} from '~/common/components';
import { FeatureIcon } from '~/common/models';
import styles from './index.module.scss';

interface FeatureContentIconsProps {
  features: FeatureIcon[];
}

export const FeatureContentIcons = React.memo<FeatureContentIconsProps>(
  ({ features }) => {
    const [withDropShadow, setWithDropShadow] = React.useState<boolean>(false);

    React.useEffect(() => {
      if (!!features) {
        setWithDropShadow(
          features.some((f) => f.contentType === 'Heading')
            ? features.length > 1
            : features.length > 0
        );
      }
    }, []);

    return (
      <div className={styles.featureContent}>
        {features && (
          <div className={styles.wrapper}>
            <div id="contentFeatures" className={styles.featureList}>
              {features.map((feature, index) => (
                <>
                  {feature.contentType === 'ImageIcon'
                    ? ImageIcon(index, feature)
                    : feature.contentType === 'AnimatedNumberIcon'
                    ? AnimatedNumberIcon(index, feature)
                    : feature.contentType === 'CtaIcon'
                    ? CallToActionIcon(index, feature)
                    : null}
                </>
              ))}
            </div>

            {withDropShadow && <Backdrop className={styles.backdrop} />}
          </div>
        )}
      </div>
    );
  }
);

interface BackdropProps {
  className?: string;
}

const Backdrop = React.memo<BackdropProps>(({ className }) => (
  <div className={className}>
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="verticalGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="transparent" />
          <stop offset="100%" stopColor="#00000080" />
        </linearGradient>
      </defs>
      <rect width="100%" height="100%" fill="url(#verticalGradient)" />
    </svg>
  </div>
));
