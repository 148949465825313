import { CompareCarsLocalStorageIDs, CompareModel, CompareCar } from './models';

const STORE_KEY = 'CRMModelVariants';
const BASE_URL = '/api/compare-cars';

export const getAllModels = (): Promise<CompareModel[]> =>
  fetch(BASE_URL).then((res) => res.json());

export const getVariant = (
  modelId: string,
  variantId: string
): Promise<CompareCar> =>
  fetch(`${BASE_URL}/${modelId}/variant?variant=${variantId}`).then((res) =>
    res.json()
  );

export const getCompareCarsLocalStore = (): CompareCarsLocalStorageIDs[] =>
  JSON.parse(localStorage.getItem(STORE_KEY) || '[]');

export const setCompareCarsLocalStore = (data: CompareCarsLocalStorageIDs[]) =>
  localStorage.setItem(STORE_KEY, JSON.stringify(data));
