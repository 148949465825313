import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useRecoilValue } from 'recoil';
import { CompareCarsLocalStorage, CompareHeadingState } from '../../store';
import {
  VehicleModelDropdown,
  VehicleVariantDropdown,
} from '../vehicle-dropdown';
import { ComparePreviewImage } from '../compare-card';
import { CompareCar } from '../../models';
import { noVehicleSelected } from '../../utils';
import classNames from 'classnames';

export const CompareSticky = React.forwardRef<HTMLDivElement>(({}, ref) => {
  const compareCars = useRecoilValue(CompareCarsLocalStorage);
  const { headerTitle } = useRecoilValue(CompareHeadingState);
  const [visible, setVisible] = useState<boolean>(false);

  const handleScrollChange = () => {
    setVisible(window.scrollY > 600);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollChange);
    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);

  return (
    <div
      className={classNames(
        styles.compareSticky,
        visible && styles.compareVisible
      )}
    >
      <div
        className={styles.headerTitle}
        dangerouslySetInnerHTML={{
          __html: headerTitle as string,
        }}
      />
      <div className={styles.flexWrapper}>
        <div className={styles.gridContainer} ref={ref}>
          {compareCars.map((vehicle) => (
            <CompareVehicle key={vehicle.id} {...vehicle} />
          ))}
        </div>
      </div>
    </div>
  );
});

const CompareVehicle = React.memo<CompareCar>((props) => {
  return noVehicleSelected(props) ? (
    <div className={styles.compareVehicleWrapper} />
  ) : (
    <div className={styles.compareVehicleWrapper}>
      <div className={styles.dropdowns}>
        <VehicleModelDropdown
          fullWidth
          id={props.id}
          modelId={props.modelPimId}
        />
        <VehicleVariantDropdown
          id={props.id}
          modelId={props.modelPimId}
          variantId={props.variantPimId}
        />
      </div>
      <div className={styles.previewImage}>
        <ComparePreviewImage id={props.id} />
      </div>
    </div>
  );
});
