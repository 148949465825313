import React from 'react';
import { Input, SvgIcon } from '@ui-elements';
import { usePortals } from 'react-portal-hook';
import { useRecoilValue } from 'recoil';
import { UserLocationState } from '~/common/store';
import { PostcodeModal } from 'common/components';
import styles from './index.module.scss';

interface PostcodeInputProps {
  label?: string;
}

export const PostcodeInput = React.memo<PostcodeInputProps>(({ label }) => {
  const location = useRecoilValue(UserLocationState);
  const portalManager = usePortals();

  const openPostcodeModal = () => {
    portalManager.open((portal) => <PostcodeModal closeModal={portal.close} />);
  };

  return (
    <div className={styles.postcodeWrapper}>
      <p>
        {label || 'Please set your postcode to see your local dealer pricing'}
      </p>
      <Input
        readOnly
        variant="outlined"
        value={location?.name}
        className={styles.postcodeTextField}
        startAdornment={<SvgIcon type="location" color="#1637a0" />}
        endAdornment={
          <span className={styles.postcodeChange} onClick={openPostcodeModal}>
            Change
          </span>
        }
      />
    </div>
  );
});
