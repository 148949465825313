import { RepaymentInfo } from '~/common/models';

let globalWidget;

export const getRepayments = (
  pimIdentifier: string,
  financeFormInput: any,
  postcode: string
): Promise<RepaymentInfo> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      codes: [pimIdentifier],
      financeFormInput,
      postCode: postcode,
    }),
  };
  return fetch(`/proxy/api/financecalculator`, requestOptions)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new Error('Server error occurred.');
    })
    .then((res) => {
      const errorDescription =
        financeFormInput.financeProductId === 'FIN_PROD_AUS_SUB_GFV' &&
        (res.finance.simulationInfo[0].result.type === 'not_applicable' ||
          res.finance.simulationInfo[0].result.simulation.financeProduct !==
            'FIN_PROD_AUS_SUB_GFV')
          ? 'Sorry, GFV isn’t yet available for this vehicle. Please check with your local participating Retailer or check back soon.'
          : res.finance.simulationInfo[0].result.type === 'na'
          ? 'Based on your deposit, the loan amount on this vehicle would be too low. Please lower your deposit to see a repayment.'
          : res.finance.simulationInfo[0].result.type === 'error'
          ? 'Something went wrong with your request. Please try again later.'
          : '';

      if (errorDescription !== '') {
        const repaymentInfo: RepaymentInfo = {
          pimIdentifier,
          financeProductId: financeFormInput.financeProductId,
          comparisonRate: 0,
          interestRate: 0,
          paymentValue: 0,
          monthlyRepayment: '',
          paymentFrequency: '',
          paymentFrequencyString: '',
          deposit: 0,
          term: '',
          residualValue: 0,
          postCode: '',
          mileage: 0,
          isGfvNotAvailableError:
            financeFormInput.financeProductId === 'FIN_PROD_AUS_SUB_GFV' &&
            (res.finance.simulationInfo[0].result.type === 'not_applicable' ||
              res.finance.simulationInfo[0].result.simulation.financeProduct !==
                'FIN_PROD_AUS_SUB_GFV'),
          isDepositTooHighError:
            res.finance.simulationInfo[0].result.type === 'na',
          isGeneralError: res.finance.simulationInfo[0].result.type === 'error',
          errorDescription,
        };
        return repaymentInfo;
      }

      const repaymentInfo: RepaymentInfo = {
        pimIdentifier,
        financeProductId: financeFormInput.financeProductId,
        comparisonRate:
          res.finance.simulationInfo[0].result.simulation.comparisonRate,
        interestRate:
          res.finance.simulationInfo[0].result.simulation.interestRate,
        paymentValue:
          res.finance.simulationInfo[0].result.simulation.paymentValue[0]
            .amount / 100,
        monthlyRepayment: getMonthlyAmountFromDisclaimers(
          res.finance.simulationInfo[0].disclaimers
        ),
        paymentFrequency:
          res.finance.simulationInfo[0].result.simulation.financePackageId,
        paymentFrequencyString: getPaymentFrequencyString(
          res.finance.simulationInfo[0].result.simulation.financePackageId
        ),
        deposit:
          res.finance.simulationInfo[0].result.simulation.deposits[0].value /
          100,
        term: res.finance.simulationInfo[0].result.simulation.loanTermPeriod
          .value,
        residualValue:
          res.finance.simulationInfo[0].result.simulation.residualValue === null
            ? 0
            : res.finance.simulationInfo[0].result.simulation.residualValue
                ?.amount / 100,
        postCode: res.finance.simulationInfo[0].postCode,
        mileage: res.finance.simulationInfo[0].result.simulation.mileage,
        isGfvNotAvailableError:
          financeFormInput.financeProductId === 'FIN_PROD_AUS_SUB_GFV' &&
          (res.finance.simulationInfo[0].result.type === 'not_applicable' ||
            res.finance.simulationInfo[0].result.simulation.financeProduct !==
              'FIN_PROD_AUS_SUB_GFV'),
        isDepositTooHighError:
          res.finance.simulationInfo[0].result.type === 'na',
        isGeneralError: res.finance.simulationInfo[0].result.type === 'error',
        errorDescription,
      };
      const eventObj = {
        detail: res.finance.simulationInfo[0].result.simulation,
        pimIdentifier,
      };
      eventObj.detail.isFinanceFormInputPersonalised =
        getFinanceFormInputPersonalised();
      window.dispatchEvent(new CustomEvent('repaymentInfoUpdated', eventObj));
      return repaymentInfo;
    })
    .catch(() => {
      const repaymentInfo: RepaymentInfo = {
        pimIdentifier,
        financeProductId: financeFormInput.financeProductId,
        comparisonRate: 0,
        interestRate: 0,
        paymentValue: 0,
        monthlyRepayment: '',
        paymentFrequency: '',
        paymentFrequencyString: '',
        deposit: 0,
        term: '',
        residualValue: 0,
        postCode: '',
        mileage: 0,
        isGfvNotAvailableError: false,
        isDepositTooHighError: false,
        isGeneralError: true,
        errorDescription:
          'Something went wrong with your request unfortunately. Please try again later.',
      };
      return repaymentInfo;
    });
};

export const getPaymentFrequencyString = (paymentFrequency: string): string => {
  if (paymentFrequency === 'Weekly') {
    return 'week';
  } else if (paymentFrequency === 'Fortnightly') {
    return 'fortnight';
  } else if (paymentFrequency === 'Monthly') {
    return 'month';
  }
  return 'week';
};

export const getFinanceFormInput = (financeFormInputEvent: any): any => {
  let financeFormInput: any = null;
  if (financeFormInputEvent) {
    financeFormInput = financeFormInputEvent.detail.event;
  } else if (
    typeof window !== 'undefined' &&
    localStorage.getItem('au_sub-dis_finance_form_input')
  ) {
    financeFormInput = JSON.parse(
      localStorage.getItem('au_sub-dis_finance_form_input') ?? ''
    );
  } else {
    // setting default values
    if (financeFormInput === null) {
      financeFormInput = {
        paymentType: 'finance',
        financeProductId: 'FIN_PROD_AUS_SUB_GFV',
        mileage: 15000,
        financePackageId: 'Weekly',
        deposit: 0,
        term: 48,
        ageRange: '23',
        homeOwner: 'true',
        creditRating: 'GOOD',
      };
    }
  }
  return financeFormInput;
};

export const getFinanceFormInputPersonalised = () => {
  return localStorage.getItem('au_sub-dis_finance_form_input') !== null;
};

export const openFinanceModal = (
  pimIdentifier: string | undefined,
  postcode: string | undefined
): void => {
  if (pimIdentifier === undefined) return;

  const modal = document.getElementById('modal');
  if (modal !== null) {
    modal.style.visibility = 'visible';
    setupWidget(pimIdentifier, postcode ?? '2000');
  }
};

export const closeFinanceModal = () => {
  const modal = document.getElementById('modal');
  if (modal !== null) {
    modal.style.visibility = 'hidden';
    globalWidget.destroy();
  }
};

export const setupWidget = (pimIdentifier: string, postcode: string) => {
  const DXP_SUBMIT_EVENT = 'continue';
  if (window['FC'] === null) {
    return;
  }
  const FCLibrary = window['FC'].InchcapeFinance;

  const config = {
    containerId: 'repayment_calculator_root',
    car: {
      code: pimIdentifier,
      featureCodes: {
        values: [],
      },
    },
    version: 'new',
    viewMode: 'full',
    postcode,
    dimensions: {
      brand: 'sub-dis',
      country: 'au',
      language: 'en-au',
      region: 'au',
    },
    externalSite: true,
  };
  FCLibrary.createWidget(config)
    .then((widget) => {
      globalWidget = widget;
      widget.on(DXP_SUBMIT_EVENT, (event) => {
        window.dispatchEvent(
          new CustomEvent(DXP_SUBMIT_EVENT, {
            detail: { event },
          })
        );
        const modal = document.getElementById('modal');
        if (modal !== null) {
          modal.style.visibility = 'hidden';
          globalWidget.destroy();
        }
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getMonthlyAmountFromDisclaimers = (disclaimers: any): string => {
  const repaymentDisclaimer = disclaimers.find(
    (x) => x.purpose === 'Repayment'
  );
  if (repaymentDisclaimer) {
    const inputString = repaymentDisclaimer.value;
    // Regular expression pattern to match dollar values
    const dollarPattern = /repayment is \$\d{1,3}(,\d{3})*(\.\d{2})?/;

    // Find all dollar values in the string
    const dollarValues = inputString.match(dollarPattern);
    const dollarStr = dollarValues[0].substring(13); // discarding the $ sign as well
    return dollarStr;
  }
  return '';
};
