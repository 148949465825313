import React from 'react';
import { PortalProvider } from 'react-portal-hook';
import { MutableSnapshot, RecoilRoot } from 'recoil';
import { CompareGrid } from './components/compare-grid';
import { CompareHeading } from './components/compare-heading';
import { CompareHeadingState } from './store';
import styles from './index.module.scss';

export interface CompareCarsProps {
  headerTitle: string;
  headerDescription: string;
  headerTooltip: string;
  specsTooltip: string;
  featuresTooltip: string;
}

export default React.memo((props: CompareCarsProps) => (
  <RecoilRoot
    initializeState={({ set }: MutableSnapshot) => {
      set(CompareHeadingState, { ...props });
    }}
  >
    <PortalProvider>
      <div className={styles.compareCars}>
        <CompareHeading />
        <CompareGrid />
      </div>
    </PortalProvider>
  </RecoilRoot>
));
