import React, { CSSProperties } from 'react';
import { CallToAction } from '~/common/models';
import { Button, SvgIcon } from '~/common/components/ui-elements';
import styles from './index.module.scss';

interface CompareCTAButtonProps {
  cta?: CallToAction;
  data: [string, string | null] | null;
}

export const CompareCTAButton: React.FC<CompareCTAButtonProps> = React.memo(
  ({ cta, data }) => {
    const _style: CSSProperties = cta
      ? {
          ...(cta.ctaColor ? { color: `#${cta.ctaColor}` } : {}),
          ...(cta.ctaColor ? { borderColor: `#${cta.ctaColor}` } : {}),
        }
      : {};

    const onClick = () => {
      if (data) {
        window['pushDigitalData'](
          {
            event: '_formNavigate',
            form: {
              name: 'compare subaru range',
              stage: cta?.ctaUrl?.name,
              details: {
                vehicleSelected: [
                  {
                    make: 'subaru',
                    model: data[0],
                    variant: data[1],
                  },
                ],
              },
            },
          },
          true
        );
      }
    };

    return (
      <div className={styles.compareButton}>
        {!!cta && !cta.ctaHidden && (
          <Button
            style={_style}
            href={cta.ctaUrl?.url || '#'}
            target={cta.ctaUrl?.target}
            type={cta.ctaButtonType}
            buttonSize={cta.ctaButtonSize}
            buttonWidth={cta.ctaFullWidth}
            onClick={onClick}
          >
            {!!cta.ctaIcon ? (
              <div>
                <SvgIcon
                  className={styles.compareDownloadIcon}
                  type={cta.ctaIcon}
                  color="#0042a6"
                />
                <a className={styles.compareButtonText}>
                  {cta.ctaUrl?.name || ''}
                </a>
              </div>
            ) : (
              <span className={styles.compareButtonText}>
                {cta.ctaUrl?.name || ''}
              </span>
            )}
          </Button>
        )}
      </div>
    );
  }
);
