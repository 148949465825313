import React from 'react';
import { HashRouteContext } from './context';

interface RouteProps {
  path: string;
  children: JSX.Element;
}

export const Route: React.FC<RouteProps> = ({ path, children }) => {
  const { route } = React.useContext(HashRouteContext);
  return route === path ? children : null;
};
