type EventData = {
  event: '_pageLoaded' | '_formNavigate' | '_formInteract';
};

export type DigitalDataEvent = EventData & Record<string, any>;

const digitalData = {
  push: (additionalData: DigitalDataEvent, _ = false) => {
    if (typeof window['digitalData'] !== 'undefined') {
      window['digitalData'].events.pushAndUpdate(additionalData);
    }
  },

  pushAll: (data: DigitalDataEvent[]) => {
    if (typeof window['digitalData'] !== 'undefined') {
      data.forEach((additionalData) => {
        window['digitalData'].events.pushAndUpdate(additionalData);
      });
    }
  },

  pushWithCommonData: (
    additionalData: DigitalDataEvent,
    excludeCommonData = false
  ) => {
    if (
      typeof window['pushDigitalData'] !== 'undefined' &&
      typeof window['pushDigitalData'] === 'function'
    ) {
      window['pushDigitalData'](additionalData, excludeCommonData);
    }
  },

  pushAllWithCommonData: (
    data: { additionalData: DigitalDataEvent; excludeCommonData?: boolean }[]
  ) => {
    if (
      typeof window['pushDigitalData'] !== 'undefined' &&
      typeof window['pushDigitalData'] === 'function'
    ) {
      data.forEach(({ additionalData, excludeCommonData }) => {
        window['pushDigitalData'](additionalData, excludeCommonData);
      });
    }
  },
};

export default digitalData;
