import React from 'react';

export type Params = {
  [key: string]: string | number | boolean | null | undefined;
};

export interface RouteOption {
  params?: Params;
  resetScroll?: boolean;
}

export interface HashRouteContext {
  route?: string;
  params: Params | null;
  navigate: (path: string, option?: RouteOption) => void;
}

export const HashRouteContext = React.createContext<HashRouteContext>({
  route: '/',
  params: null,
  navigate: () => {},
});
