import React from 'react';
import styles from './index.module.scss';

export const PrivacyCollectionStatement = React.memo(() => (
  <div className={styles.PCS}>
    <h5>Privacy Collection Statement</h5>
    <p>
      Subaru (Aust) Pty Ltd trading as (&apos;Subaru Australia&apos;) respects
      your privacy and is bound by the Australian Privacy Principles set out in
      the Privacy Act 1988 (Cth). Subaru Australia collects personal information
      to provide the product or service which you have requested. If you do not
      provide us with your personal information, we may not be able to respond
      to your enquiry. If you have consented, or if otherwise permitted by law,
      Subaru Australia will send you marketing communications, which may be of
      interest to you, including updates and special offers. Subaru Australia
      may disclose your personal information to Subaru Retailers, related
      companies or third parties that provide us with services, including to
      overseas locations (such as Japan, UK &amp; USA). You may contact Subaru
      Australia to seek access, update or correct your personal information. If
      you have any concerns or queries regarding our handling of your personal
      information, please let us know using the contact details set out below.
      The Privacy Officer shall review, investigate and respond to any privacy
      compliant within 10 working days (or advise if otherwise) of receipt of
      such complaint. For more information, read our{' '}
      <a href="/privacy-policy">Privacy Policy</a> or write to the Privacy
      Officer, Subaru Australia, Level 2, 4 Burbank Place, Norwest NSW 2153 or
      send an email to{' '}
      <a href="mailto:privacy.officer@subaru.com.au">
        privacy.officer@subaru.com.au
      </a>
    </p>
  </div>
));
