import React from 'react';
import cn from 'classnames';
import { ColourOption } from '~/common/models';
import { useSpring, animated } from 'react-spring';
import styles from './index.module.scss';

interface ColourSwatchProps {
  key?: string | number;
  delay?: number;
  isActive?: boolean;
  colour: ColourOption;
  onClick: () => void;
  className?: string;
}

export const ColourSwatch = React.memo<ColourSwatchProps>(
  ({ isActive, onClick, colour, delay, className }) => {
    const springProps = useSpring({
      delay,
      immediate: true,
      from: { transform: 'scale(0)', opacity: 0 },
      to: { transform: 'scale(1)', opacity: 1 },
    });

    return (
      <animated.div
        onClick={onClick}
        style={springProps}
        className={cn(className, styles.colourOption, {
          [styles.colorSelected]: isActive,
        })}
      >
        <div style={{ backgroundColor: `#${colour.hexColourCode}` }} />
      </animated.div>
    );
  }
);
