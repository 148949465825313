import React, { CSSProperties } from 'react';
import cn from 'classnames';
import { useSpring, animated } from 'react-spring';
import {
  ButtonProps,
  ButtonTypeEnum,
  IconEnum,
  SvgIcon,
  AnimatedNumber,
  Button,
  FloatingDisclaimer,
} from '@ui-elements';
import { FeatureCTA, FeatureIcon } from '~/common/models';
import {
  colorBlack,
  colorPrimary,
  colorWhite,
  toCamelCase,
} from '~/common/utils';
import styles from './index.module.scss';

type ImageIconProps = {
  id?: string;
  key: string | number;
  delay: number;
  className?: string;
  style?: CSSProperties;
};

const AnimatedIcon: React.FC<ImageIconProps> = ({
  id,
  delay,
  className,
  children,
  style,
}) => {
  const springProps = useSpring({
    delay,
    from: { transform: 'translateY(100%)', opacity: 0 },
    to: { transform: 'translateY(0%)', opacity: 1 },
  });

  return (
    <animated.div
      key={delay}
      id={id}
      className={className}
      style={{ ...springProps, ...style }}
    >
      {children}
    </animated.div>
  );
};

export const ImageIcon = (
  index: number,
  feature: FeatureIcon,
  className?: string
) => {
  const color = `#${feature.fontColor || colorWhite}`;
  const dividerColor = `#${feature.dividerColor || colorWhite}`;

  return (
    <AnimatedIcon
      key={index}
      id={`${feature.contentType}-${index + 1}`}
      className={cn(
        styles.feature,
        {
          [styles.hideOnMobile]: feature.hideOnMobile,
          [styles.persistDivider]: feature.showDivider,
          [styles[`align-${feature.alignment ?? 'center'}`]]: true,
        },
        className
      )}
      delay={(index + 1) * 200}
      style={{
        ...(index > 0
          ? {
              borderLeftColor: dividerColor,
            }
          : {}),
      }}
    >
      <div>
        <img
          data-field="image"
          src={feature.imageUrl}
          alt={feature.displayText}
        />
      </div>

      <FloatingDisclaimer
        disclaimer={feature.disclaimer}
        wrapperStyle={{
          textAlign: feature.alignment || 'center',
        }}
        className={styles.textContainer}
      >
        {({ setReference, getReferenceProps }) => (
          <>
            <span
              style={{
                color,
                display: 'inline',
              }}
              data-field="displayText"
              dangerouslySetInnerHTML={{ __html: feature.displayText || '' }}
            />

            {!!feature.disclaimer && (
              <span
                ref={setReference}
                {...getReferenceProps()}
                style={{
                  color,
                  cursor: 'pointer',
                  display: 'inline',
                }}
              >
                *
              </span>
            )}
          </>
        )}
      </FloatingDisclaimer>
    </AnimatedIcon>
  );
};

export const AnimatedNumberIcon = (
  index: number,
  feature: FeatureIcon,
  className?: string
) => {
  const color = `#${feature.fontColor || colorWhite}`;
  const dividerColor = `#${feature.dividerColor || colorWhite}`;

  return (
    <AnimatedIcon
      key={index}
      id={`${feature.contentType}-${index + 1}`}
      className={cn(
        styles.feature,
        {
          [styles.hideOnMobile]: feature.hideOnMobile,
          [styles.persistDivider]: feature.showDivider,
          [styles[`align-${feature.alignment ?? 'center'}`]]: true,
        },
        className
      )}
      delay={(index + 1) * 200}
      style={{
        ...(index > 0
          ? {
              borderLeftColor: dividerColor,
            }
          : {}),
      }}
    >
      <div className={styles.animatedNumberIcon}>
        <AnimatedNumber
          delay={1000}
          number={feature.animatedNumber}
          styles={{
            fontSize: 30,
            fontWeight: 800,
            color,
          }}
        />
        <div
          style={{
            color,
          }}
        >
          {feature.extension}
        </div>
      </div>

      <FloatingDisclaimer disclaimer={feature.disclaimer}>
        {({ setReference, getReferenceProps }) => (
          <div
            className={styles.textContainer}
            style={{
              textAlign: feature.alignment || 'center',
            }}
          >
            <span
              style={{
                color,
                display: 'inline',
              }}
              data-field="displayText"
              dangerouslySetInnerHTML={{ __html: feature.displayText || '' }}
            />
            {!!feature.disclaimer && (
              <span
                ref={setReference}
                {...getReferenceProps()}
                style={{
                  color,
                  cursor: 'pointer',
                  display: 'inline',
                }}
              >
                *
              </span>
            )}
          </div>
        )}
      </FloatingDisclaimer>
    </AnimatedIcon>
  );
};

export const CallToActionIcon = (
  index: number,
  feature: FeatureIcon,
  className?: string
) => {
  const color = `#${feature.fontColor || colorWhite}`;
  const dividerColor = `#${feature.dividerColor || colorWhite}`;
  return (
    <AnimatedIcon
      key={index}
      id={`${feature.contentType}-${index + 1}`}
      className={cn(
        styles.feature,
        styles.callToAction,
        {
          [styles.hideOnMobile]: feature.hideOnMobile,
          [styles.persistDivider]: feature.showDivider,
        },
        className
      )}
      delay={(index + 1) * 200}
      style={{
        ...(index > 0
          ? {
              borderLeftColor: dividerColor,
            }
          : {}),
      }}
    >
      <Button
        href={feature.link.url}
        target={feature.link.target}
        type={feature.buttonType}
        data-field="ctaButton"
        style={
          feature.buttonType === 'borderedSecondary'
            ? { color, borderColor: color }
            : {}
        }
      >
        <span dangerouslySetInnerHTML={{ __html: feature.link.name || '' }} />
      </Button>
    </AnimatedIcon>
  );
};

export const CallToActionIconV2 = (
  index: number,
  feature: FeatureCTA,
  className?: string
) => {
  const color = `#${feature.ctaColor || colorPrimary}`;
  const dividerColor = `#${
    feature.dividerColor || feature.ctaColor || colorBlack
  }`;
  const _style: CSSProperties = {
    ...(feature.ctaColor ? { color: `#${color}` } : {}),
    ...(feature.ctaColor ? { borderColor: `#${color}` } : {}),
    padding: '0',
  };
  const buttonProps: ButtonProps = {
    type: toCamelCase(feature.ctaButtonType) as keyof typeof ButtonTypeEnum,
    href: feature.ctaUrl?.url,
    target: feature.ctaUrl?.target || '_self',
    style: _style,
    buttonWidth: feature.ctaFullWidth,
  };
  return (
    <AnimatedIcon
      key={index}
      id={`CallToAction-${index + 1}`}
      className={cn(
        styles.feature,
        styles.callToAction,
        {
          [styles.hideOnMobile]: feature.ctaHideInMobile,
          [styles.persistDivider]: feature.showDivider,
        },
        className
      )}
      style={
        index > 0
          ? {
              borderLeftColor: dividerColor,
            }
          : {}
      }
      delay={(index + 1) * 200}
    >
      <Button data-field="ctaButton" {...buttonProps}>
        {feature.ctaIcon && feature.ctaIcon !== 'none' && (
          <SvgIcon
            type={feature.ctaIcon as keyof typeof IconEnum}
            color={color}
            size={1.2}
            strokeWidth={2}
            style={{
              padding: '0px 5px 1.5px 0px',
              alignSelf: 'start',
              lineHeight: 'unset',
            }}
          />
        )}
        <span
          style={buttonProps.type === 'text' ? { color } : {}}
          dangerouslySetInnerHTML={{ __html: feature.ctaUrl?.name || '' }}
        />
      </Button>
    </AnimatedIcon>
  );
};
