import React from 'react';
import { Modal } from '../ui-elements/modal';
import './index.scss';

interface Props {
  closeModal: () => void;
  title?: string;
  element: any;
}

export const GenericModal = React.memo<Props>(
  ({ closeModal, title, element }) => {
    return (
      <Modal closeModal={closeModal} lockScroll>
        <div className="genericModal">
          {title !== null && title !== '' ? (
            <h1 className="title">{title}</h1>
          ) : (
            ''
          )}
          {element}
        </div>
      </Modal>
    );
  }
);
